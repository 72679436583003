import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import HslShimmer from '@hsl-fi/shimmer'
import './Shimmer.scss'

const Shimmer = ({ active, children, className, style, ...props }) => (
  <div className={classnames('common__shimmer', className)} style={style}>
    <HslShimmer active={active} {...props}>
      {children}
    </HslShimmer>
  </div>
)

Shimmer.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Shimmer
