import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Page from '../../../common/components/Page'
import WhiteBox from '../../../common/components/WhiteBox'
import apiStore from '../../../common/stores/apiStore'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import { H1, P } from '../../../common/components/Typography'
import { ExternalLinkButton } from '../../../common/components/Button'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import { COMPANY_ADMIN_LOGIN_URL } from '../../anonymous/constants'
import { INVITE_STATUS } from '../../company/constants'
import { captureException } from '../../../common/errorTracking'

const NewCompanyAdminScreen = () => {
  const [t, i18n] = useTranslation()
  const [isValidating, setIsValidating] = useState(true)
  const [inviteStatus, setInviteStatus] = useState()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('invite_id')

  useEffect(() => {
    const validateInvite = async () => {
      try {
        setIsValidating(true)

        const { status } =
          await apiStore.endpoints.invite.validateCompanyAdminInvite(inviteId, {
            credentials: 'omit',
          })

        setInviteStatus(status)

        setIsValidating(false)
      } catch (e) {
        captureException({
          message: 'Company admin invite validation failed',
          extra: {
            error: e,
          },
        })

        uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)

        setIsValidating(false)
      }
    }

    validateInvite()
  }, [])

  if (inviteStatus !== INVITE_STATUS.VALID && !isValidating) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.INVALID_LINK}
        query={{ status: inviteStatus }}
      />
    )
  }

  return (
    <Page>
      <WhiteBox>
        <H1>{t('SELF_SERVICE:START_NEW_COMPANY_ADMIN_FLOW.HEADING')}</H1>
        <P>{t('SELF_SERVICE:START_NEW_COMPANY_ADMIN_FLOW.PARAGRAPH_1')}</P>
        <ExternalLinkButton
          href={`${COMPANY_ADMIN_LOGIN_URL}?ui_locales=${i18n.language}&email=&redirectTo=self-service/auth-success?inviteId=${inviteId}`}
        >
          {t('SELF_SERVICE:START_NEW_COMPANY_ADMIN_FLOW.CONTINUE_SIGNIN')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default NewCompanyAdminScreen
