import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { pageIndexToNumber } from './Pagination'
import PaginationButton from './PaginationButton'

const PaginationPageButton = ({ index, currentPageIndex, onPageClick }) => {
  const [t] = useTranslation()
  const pageNumberLabel = pageIndexToNumber(index)
  const isCurrentPage = index === currentPageIndex
  return (
    <PaginationButton
      className={classNames(isCurrentPage && 'border-2 border-blue')}
      key={index}
      onClick={() => onPageClick(index)}
      disabled={isCurrentPage}
      aria-label={t('COMMON:PAGINATION.PAGE_X', {
        page: pageIndexToNumber(index),
      })}
    >
      {pageNumberLabel}
    </PaginationButton>
  )
}

PaginationPageButton.propTypes = {
  index: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
}

export default PaginationPageButton
