import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './SuspenseImage.scss'

const SuspenseImage = ({ src, className, ...rest }) => {
  cache.read(src)

  return (
    <img
      src={src}
      className={classnames('common__suspense-image', className)}
      {...rest}
    />
  )
}

SuspenseImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const cache = {
  __cache: {},

  read(src) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image()

        img.onload = () => {
          this.__cache[src] = true
          resolve(this.__cache[src])
        }

        img.src = src
      }).then(() => {
        this.__cache[src] = true
      })
    }

    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src]
    }

    return this.__cache[src]
  },
}

export default SuspenseImage
