"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeExtraWhitespacesFromStartAndMiddle = exports.removeExtraWhitespaces = exports.generateId = exports.capitalize = exports.stringToKebab = exports.sanitize = exports.snakeToCamel = void 0;
var snakeToCamel = function (str) {
    if (!str || str === null)
        return str;
    return str.replace(/([-_][a-z])/g, function (group) {
        return group.toUpperCase().replace('-', '').replace('_', '');
    });
};
exports.snakeToCamel = snakeToCamel;
var sanitize = function (str) {
    if (!str || str === null)
        return str;
    return str.replace(/([Ää])|([Åå])/g, 'a').replace(/([Öö])/g, 'o');
};
exports.sanitize = sanitize;
var stringToKebab = function (str) {
    var _a;
    if (!str || str === null)
        return str;
    return (_a = str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)) === null || _a === void 0 ? void 0 : _a.map(function (x) { return x.toLowerCase(); }).join('-');
};
exports.stringToKebab = stringToKebab;
var capitalize = function (str) {
    if (!str || str === null)
        return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};
exports.capitalize = capitalize;
var generateId = function (str) { return exports.stringToKebab(exports.sanitize(str)); };
exports.generateId = generateId;
var removeExtraWhitespaces = function (str) {
    if (!str || str === null)
        return str;
    return str.replace(/\s+/g, ' ').trim();
};
exports.removeExtraWhitespaces = removeExtraWhitespaces;
var removeExtraWhitespacesFromStartAndMiddle = function (str) {
    if (!str || str === null)
        return str;
    return str.replace(/\s+/g, ' ').trimStart();
};
exports.removeExtraWhitespacesFromStartAndMiddle = removeExtraWhitespacesFromStartAndMiddle;
