import React, { useEffect, useState } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import ContainerSpinner from '@hsl-fi/container-spinner'
import { Redirect } from '../../routes/components/Link'
import userStore from '../../../common/stores/userStore'
import withAuthentication from '../../../common/hoc/withAuthentication'
import ROUTES from '../../routes/routes'
import apiStore from '../../../common/stores/apiStore'
import { DEFAULT_LANGUAGE } from '../../../common/constants'
import { setCookieConsentLanguage } from '../../../common/hooks/useCookieConsent'

const LoginSuccess = () => {
  const [, i18n] = useTranslation()
  const [isReady, setIsReady] = useState(false)
  const [searchParams] = useSearchParams()
  const from = searchParams.get('from')

  useEffect(() => {
    const initialize = async () => {
      try {
        const profile = await apiStore.fetchUser()
        const language = profile?.locale ?? DEFAULT_LANGUAGE.code

        i18n.changeLanguage(language)
        setCookieConsentLanguage(language)
      } catch (e) {
        // Fail silently
      } finally {
        setIsReady(true)
      }
    }

    initialize()
  }, [])

  if (!isReady) {
    return <ContainerSpinner visible />
  }

  if (from) {
    return <Navigate to={`/${from}`} />
  }

  // Supervisor
  if (userStore.isSupervisor) {
    return <Redirect route={ROUTES.SUPERVISOR.COMPANIES} />
  }

  // Company admin
  if (userStore.isCompanyAdmin) {
    return <Redirect route={ROUTES.COMPANY_ADMIN.SELECT} />
  }

  return <Redirect route={ROUTES.ROOT} query={{ error: ERROR_NOT_ADMIN }} />
}

export const ERROR_NOT_ADMIN = 'not-admin'

export default withAuthentication(observer(LoginSuccess))
