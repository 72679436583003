import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDisabled } from '../../../assets/icons/arrow-right-disabled.svg'
import { ReactComponent as Arrow } from '../../../assets/icons/arrow-right.svg'
import { times } from '../../utility'
import Dropdown from '../Dropdown/Dropdown'
import PaginationButton from './PaginationButton'
import PaginationDivider from './PaginationDivider'
import PaginationEllipsis from './PaginationEllipsis'
import PaginationPageButton from './PaginationPageButton'

const pageSizeOptions = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
  { text: '500', value: 500 },
]

export const defaultPageSize = pageSizeOptions[0].value

const Pagination = ({
  pageNumber,
  pageSize,
  onChange,
  withPageSize,
  totalResults,
  scrollToRef,
  className,
}) => {
  const normalizedSize = normalizePageSize(pageSize)
  const [t] = useTranslation()
  const currentPageIndex = pageNumber - 1
  const numberOfPages = Math.ceil(totalResults / normalizedSize) || 1 // fall back to one page even if there are zero results
  const isOnFirstPage = currentPageIndex === 0
  const isOnLastPage = pageIndexToNumber(currentPageIndex) === numberOfPages

  const onPageClick = (pageAsIndex, size = normalizedSize) => {
    onChange({
      pageNumber: pageIndexToNumber(pageAsIndex),
      pageSize: size,
    })

    if (scrollToRef) {
      window.scrollTo(0, scrollToRef.current.offsetTop)
    }
  }

  return (
    <div className={classNames('mt-lg flex items-center', className)}>
      <div className="flex gap-sm">
        <PaginationButton
          className="rotate-180 "
          onClick={() => onPageClick(currentPageIndex - 1)}
          disabled={isOnFirstPage}
          aria-label={t('COMMON:PAGINATION.PREVIOUS_PAGE')}
        >
          {isOnFirstPage ? <ArrowDisabled /> : <Arrow />}
        </PaginationButton>
        <PaginationDivider />
        {numberOfPages <= MAX_PAGES_AT_ENDS &&
          times(numberOfPages)
            .slice(0, MAX_PAGES_AT_ENDS)
            .map((index) => (
              <PaginationPageButton
                key={index}
                index={index}
                currentPageIndex={currentPageIndex}
                onPageClick={onPageClick}
              />
            ))}
        {numberOfPages > MAX_PAGES_AT_ENDS ? (
          currentPageIndex < 2 ? (
            times(numberOfPages)
              .slice(0, MAX_PAGES_AT_ENDS)
              .map((index) => (
                <PaginationPageButton
                  key={index}
                  index={index}
                  currentPageIndex={currentPageIndex}
                  onPageClick={onPageClick}
                />
              ))
          ) : (
            <>
              {
                <PaginationPageButton
                  index={0}
                  currentPageIndex={currentPageIndex}
                  onPageClick={onPageClick}
                />
              }
              <PaginationEllipsis />
            </>
          )
        ) : null}
        {numberOfPages > MAX_PAGES_AT_ENDS
          ? currentPageIndex < 2 || currentPageIndex > numberOfPages - 3
            ? null
            : times(numberOfPages - pageIndexToNumber(currentPageIndex), (n) =>
                currentPageIndex <= numberOfPages ? n + currentPageIndex : n
              )
                .slice(0, MAX_PAGES_IN_MIDDLE)
                .map((index) => (
                  <PaginationPageButton
                    key={index}
                    index={index}
                    currentPageIndex={currentPageIndex}
                    onPageClick={onPageClick}
                  />
                ))
          : null}
        {numberOfPages > MAX_PAGES_AT_ENDS ? (
          currentPageIndex > numberOfPages - 3 ? (
            times(numberOfPages)
              .slice(-MAX_PAGES_AT_ENDS)
              .map((index) => (
                <PaginationPageButton
                  key={index}
                  index={index}
                  currentPageIndex={currentPageIndex}
                  onPageClick={onPageClick}
                />
              ))
          ) : (
            <>
              <PaginationEllipsis />
              {
                <PaginationPageButton
                  index={numberOfPages - 1}
                  currentPageIndex={currentPageIndex}
                  onPageClick={onPageClick}
                />
              }
            </>
          )
        ) : null}
        <PaginationDivider />
        <PaginationButton
          onClick={() => onPageClick(pageIndexToNumber(currentPageIndex))}
          disabled={isOnLastPage}
          aria-label={t('COMMON:PAGINATION.NEXT_PAGE')}
        >
          {isOnLastPage ? <ArrowDisabled /> : <Arrow />}
        </PaginationButton>
      </div>
      <div className="ml-auto">
        {!!(withPageSize && totalResults) && (
          <div className="flex items-center">
            <label id="page-size-select-label" className="mr-sm">
              {t('COMMON:PAGINATION.PAGE_SIZE_OPTIONS_LABEL')}
            </label>
            <Dropdown
              aria-labelledby="page-size-select-label"
              className="min-w-[100px]"
              options={pageSizeOptions}
              onChange={(value) => onPageClick(0, value)}
              value={normalizedSize}
            />
            <div className="ml-xl">
              {t('COMMON:SHOWING_RESULTS', {
                start: currentPageIndex * normalizedSize + 1,
                end: Math.min(
                  currentPageIndex * normalizedSize + normalizedSize,
                  totalResults
                ),
                total: totalResults,
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Pagination.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  withPageSize: PropTypes.bool,
  totalResults: PropTypes.number.isRequired,
  left: PropTypes.node,
  scrollToRef: PropTypes.object,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

const MAX_PAGES_IN_MIDDLE = 2
const MAX_PAGES_AT_ENDS = 3

export const normalizePageSize = (value, defaultValue = defaultPageSize) =>
  Number(value ?? defaultValue)

export const normalizePageNumber = (value) => (value ? Number(value) : 1)

export const pageNumberToIndex = (pageNumber) =>
  normalizePageNumber(pageNumber) - 1

export const pageIndexToNumber = (pageIndex) =>
  typeof pageIndex === 'undefined' ? 1 : Number(pageIndex) + 1

export default Pagination
