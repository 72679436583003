import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../constants'
import { swapLanguagePrefix } from '../../modules/routes/routes'
import './LanguagePicker.scss'

const LanguagePicker = ({ className, style }) => {
  const [, i18n] = useTranslation()
  const location = useLocation()

  return (
    <div
      className={classnames('common__language-picker', className)}
      style={style}
    >
      {LANGUAGES.map((language) => {
        const to = swapLanguagePrefix(location, language.code)

        return (
          <a
            key={language.code}
            href={to}
            className={classnames('common__language-picker__option', {
              'common__language-picker__option--active':
                language.code === i18n.language,
            })}
          >
            {language.code}
          </a>
        )
      })}
    </div>
  )
}

LanguagePicker.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default LanguagePicker
