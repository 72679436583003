export const TICKET_TYPES = {
  SINGLE: 'SINGLE',
  ZONE_EXTENSION: 'ZONE_EXTENSION',
  DAY: 'DAY',
  SEASON: 'SEASON',
  UNDEFINED: 'UNDEFINED',
  SERIAL: 'SERIAL',
  GENERAL: 'GENERAL_SEASON',
}

export const TICKET_ORDER_TYPES = {
  MOBILE_TICKET_CODE: 'MOBILE_TICKET_CODE',
  PAPER_CARD_TICKET: 'PAPER_CARD_TICKET',
}

export const INVITE_STATUS = {
  VALID: 'VALID',
  EXPIRED: 'EXPIRED',
  REVOKED: 'REVOKED',
  ALREADY_ACCEPTED: 'ALREADY-ACCEPTED',
  INCORRECT_ROLE: 'INCORRECT-ROLE', // role set in the invitation and the role of the user are different
  TOS_NOT_ACCEPTED: 'TOS-NOT-ACCEPTED', // company has not accepted terms of service
}
