import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { hasUserVerifiedEmail } from '../../../common/helpers'
import { Redirect } from '../../routes/components/Link'
import apiStore from '../../../common/stores/apiStore'
import ROUTES from '../../routes/routes'
import userStore from '../../../common/stores/userStore'
import {
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  BENEFIT_TYPE_SEASON_TICKET,
  USER_ROLES,
} from '../../../common/constants'

const AuthSuccessScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')
  const [isLoading, setIsLoading] = useState(true)
  const [hasVerifiedWorkEmailWithToken, setHasVerifiedWorkEmailWithToken] =
    useState(false)
  const [email, setEmail] = useState('')
  const [hasStronglyIdentified, setHasStronglyIdentified] = useState(false)
  const [benefitType, setBenefitType] = useState()

  useEffect(() => {
    // Checks if the user has only partially completed the flow. If so,
    // redirects them to the next logical step.
    const redirectToNextIncompleteStep = async () => {
      setIsLoading(true)

      try {
        const [profile, user, invite] = await Promise.all([
          apiStore.endpoints.user.getProfile(),
          apiStore.fetchUser(),
          apiStore.endpoints.invite.getInviteById(inviteId),
        ])

        const [firstBenefit] = invite.benefits ?? []

        userStore.lastUserRole = USER_ROLES.BENEFICIARY

        setEmail(invite.email)
        setBenefitType(firstBenefit?.benefitType ?? BENEFIT_TYPE_SEASON_TICKET)

        const hasStronglyIdentified =
          user?.['https://oneportal.trivore.com/claims/strong_identification']
            ?.identified || false
        if (hasStronglyIdentified) {
          setHasStronglyIdentified(true)
        }

        const hasVerifiedWorkEmailWithToken = hasUserVerifiedEmail(
          invite.email,
          profile
        )
        if (hasVerifiedWorkEmailWithToken) {
          // TODO: Get and set the company ID so that we can pass it to the VerificationSuccess component as usual.
          setHasVerifiedWorkEmailWithToken(true)
        }
      } catch (e) {
        window.alert(t('COMMON:ERRORS.CLIENT_ERROR'))
      }

      setIsLoading(false)
    }

    redirectToNextIncompleteStep()
  }, [t])

  if (isLoading) {
    return null
  }

  if (hasStronglyIdentified && hasVerifiedWorkEmailWithToken) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_SUCCESS}
        query={{ inviteId, benefitType }}
      />
    )
  }

  if (hasVerifiedWorkEmailWithToken) {
    if (
      benefitType === BENEFIT_TYPE_ANNUAL_BALANCE ||
      benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE
    ) {
      return (
        <Redirect
          route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_SUCCESS}
          query={{ inviteId, benefitType }}
        />
      )
    }

    return (
      <Redirect
        route={ROUTES.BENEFICIARY.VERIFICATION_SUCCESS}
        query={{ inviteId, benefitType }}
      />
    )
  }

  // By default, redirect to verification form.
  return (
    <Redirect
      route={ROUTES.BENEFICIARY.VERIFICATION}
      query={{ inviteId, email, benefitType }}
    />
  )
}

AuthSuccessScreen.propTypes = {
  location: PropTypes.object,
}

export default observer(AuthSuccessScreen)
