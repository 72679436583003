import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

const DocumentTitleContext = createContext(null)

export const DocumentTitleContextProvider = ({ children }) => {
  const [t] = useTranslation()
  const [titles, setTitles] = useState([])

  useEffect(() => {
    let fullTitle = titles.filter((t) => t).join(' - ')
    if (fullTitle) {
      fullTitle += ' | '
    }
    fullTitle += t('COMMON:COMPANY_PORTAL')
    document.title = fullTitle
  }, [titles])

  const setTitle = useCallback(
    ({ title, level }) => {
      setTitles((current) => {
        const copy = [...current]
        copy[level] = title
        return copy
      })
    },
    [setTitles]
  )

  return (
    <DocumentTitleContext.Provider value={{ setTitle }}>
      {children}
    </DocumentTitleContext.Provider>
  )
}

DocumentTitleContextProvider.propTypes = {
  children: PropTypes.node,
}

export const useDocumentTitle = ({ title, level }) => {
  const ctx = useContext(DocumentTitleContext)
  if (!ctx) {
    throw new Error('No provider for DocumentTitleContext found')
  }
  useEffect(() => {
    if (title) {
      ctx.setTitle({ title, level })
      return () => ctx.setTitle({ title: undefined, level })
    }
  }, [title, level])
}
