import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import ContainerSpinner from '@hsl-fi/container-spinner'
import apiStore from '../../../common/stores/apiStore'
import { USER_ROLES } from '../../../common/constants'
import {
  hasUserVerifiedEmail,
  hasUserStronglyIdentified,
} from '../../../common/helpers'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'

const SelfServiceAuthSuccessScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState('')

  const [flow, setFlow] = useState({
    hasCompletedFlow: false,
    hasVerifiedWorkEmailWithToken: false,
  })

  const clientError = t('COMMON:ERRORS.CLIENT_ERROR')

  useEffect(() => {
    const determineNextStep = async () => {
      try {
        setIsLoading(true)

        const [profile, user, inviteDetails] = await Promise.all([
          apiStore.endpoints.user.getProfile(),
          apiStore.fetchUser(),
          apiStore.endpoints.invite.getInviteById(inviteId),
        ])
        setEmail(inviteDetails.email)
        setFlow({
          hasVerifiedWorkEmailWithToken: hasUserVerifiedEmail(
            inviteDetails.email,
            profile
          ),
          hasCompletedFlow:
            user.role === USER_ROLES.ADMIN && hasUserStronglyIdentified(user),
        })

        setIsLoading(false)
      } catch (e) {
        window.alert(clientError)
      }
    }

    determineNextStep()
  }, [inviteId, clientError])

  if (isLoading) {
    return <ContainerSpinner visible={true} />
  }

  if (flow.hasCompletedFlow && flow.hasVerifiedWorkEmailWithToken) {
    return (
      <Redirect
        route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_SUCCESS}
        query={{ inviteId }}
      />
    )
  }

  if (flow.hasVerifiedWorkEmailWithToken) {
    return (
      <Redirect
        route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION}
        query={{ inviteId }}
      />
    )
  }

  return (
    <Redirect
      route={ROUTES.SELF_SERVICE.VERIFICATION}
      query={{ inviteId, email }}
    />
  )
}

export default observer(SelfServiceAuthSuccessScreen)
