"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportaionIcons = exports.TransportMode = exports.TransportationServices = void 0;
var TransportationServices;
(function (TransportationServices) {
    TransportationServices["BUS"] = "BUS";
    TransportationServices["TRAM"] = "TRAM";
    TransportationServices["RAIL"] = "RAIL";
    TransportationServices["SUBWAY"] = "SUBWAY";
    TransportationServices["FERRY"] = "FERRY";
    TransportationServices["CITYBIKE"] = "CITYBIKE";
})(TransportationServices = exports.TransportationServices || (exports.TransportationServices = {}));
var TransportMode;
(function (TransportMode) {
    TransportMode[TransportMode["EXCEPTION"] = 0] = "EXCEPTION";
    TransportMode[TransportMode["BUS"] = 1] = "BUS";
    TransportMode[TransportMode["TRAM"] = 2] = "TRAM";
    TransportMode[TransportMode["RAIL"] = 3] = "RAIL";
    TransportMode[TransportMode["SUBWAY"] = 4] = "SUBWAY";
    TransportMode[TransportMode["FERRY"] = 5] = "FERRY";
    TransportMode[TransportMode["CITYBIKE"] = 6] = "CITYBIKE";
})(TransportMode = exports.TransportMode || (exports.TransportMode = {}));
var TransportaionIcons;
(function (TransportaionIcons) {
    TransportaionIcons["RouteTram"] = "tram-rectangle";
    TransportaionIcons["RouteRail"] = "train-rectangle";
    TransportaionIcons["RouteBus"] = "bus-rectangle";
    TransportaionIcons["RouteSubway"] = "metro-rectangle";
    TransportaionIcons["RouteFerry"] = "ferry-rectangle";
    TransportaionIcons["Stop"] = "stop";
})(TransportaionIcons = exports.TransportaionIcons || (exports.TransportaionIcons = {}));
