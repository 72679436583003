import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { USER_ROLES } from '../constants'

class UserStore {
  user = {}

  // This should only be used for determining which user role was being
  // used on user session expiration. We can then redirect the user to
  // the correct role-specific login screen.
  lastUserRole = undefined

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'UserStore',
      properties: ['lastUserRole'],
      storage: window.localStorage,
    })
  }

  setUser = (user, skipSettingLastUserRole = false) => {
    this.user = user

    if (!skipSettingLastUserRole) {
      this.lastUserRole = user.role
    }
  }

  get isBeneficiary() {
    return this.user.role === USER_ROLES.BENEFICIARY
  }

  get isCompanyAdmin() {
    return this.user.role === USER_ROLES.ADMIN
  }

  get isSupervisor() {
    return this.user.role === USER_ROLES.HSL_ADMIN
  }
}

export default new UserStore()
