import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { H1, P, fontSizePropType } from './Typography'
import './Hero.scss'

const Hero = ({
  title,
  titleLinkTo,
  subtitle,
  right,
  className,
  bodyClassName,
  leftClassName,
  headerFontSize = 'large',
  rightClassName,
  style,
}) => (
  <div className={classnames('common__hero', className)} style={style}>
    <div className={classnames('common__hero__body', bodyClassName)}>
      <div
        data-cy="hero"
        className={classnames('common__hero__left', leftClassName)}
      >
        {titleLinkTo ? (
          <Link to={titleLinkTo} className="common__hero__title-link">
            <H1 fontSize={headerFontSize} style={{ marginBottom: 0 }}>
              {title}
            </H1>
          </Link>
        ) : (
          <H1 fontSize={headerFontSize} style={{ marginBottom: 0 }}>
            {title}
          </H1>
        )}
        {Boolean(subtitle) && <P style={{ marginBottom: 0 }}>{subtitle}</P>}
      </div>
      {Boolean(right) && (
        <div className={classnames('common__hero__right', rightClassName)}>
          {right}
        </div>
      )}
    </div>
  </div>
)

Hero.propTypes = {
  title: PropTypes.string,
  titleLinkTo: PropTypes.string,
  subtitle: PropTypes.string,
  right: PropTypes.node,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  leftClassName: PropTypes.string,
  rightClassName: PropTypes.string,
  style: PropTypes.object,
  headerFontSize: fontSizePropType,
}

export default Hero
