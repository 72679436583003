import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RadioGroup from './RadioGroup'
import TextInput from './TextInput'
import { P } from './Typography'

export const ELECTRONIC_INVOICE = 'ELECTRONIC'
export const PAPER_INVOICE = 'PAPER'

const BillingMethodInput = ({ values, onChange, errors, style }) => {
  const [t] = useTranslation()

  return (
    <div style={style}>
      <RadioGroup
        value={values.billingMethod}
        hasError={Boolean(errors.billingMethod)}
        options={[
          {
            value: ELECTRONIC_INVOICE,
            label: t('COMMON:BILLING_METHODS.ELECTRONIC_INVOICE'),
          },
          {
            value: PAPER_INVOICE,
            label: t('COMMON:BILLING_METHODS.PAPER_INVOICE'),
          },
        ]}
        onCheck={(value) => onChange({ ...values, billingMethod: value })}
        style={{ marginBottom: '30px' }}
      />
      {values.billingMethod === ELECTRONIC_INVOICE && (
        <>
          <TextInput
            label={t('COMMON:BILLING_METHODS.BILLING_INTERMEDIARY')}
            required
            value={values.billingIntermediary}
            hasError={Boolean(errors.billingIntermediary)}
            onChange={({ target }) =>
              onChange({ ...values, billingIntermediary: target.value })
            }
          />
          <TextInput
            label={t('COMMON:BILLING_METHODS.ELECTRONIC_INVOICE_ADDRESS')}
            required
            value={values.electronicInvoiceAddress}
            hasError={Boolean(errors.electronicInvoiceAddress)}
            onChange={({ target }) =>
              onChange({ ...values, electronicInvoiceAddress: target.value })
            }
          />
        </>
      )}
      <P>
        {values.billingMethod === ELECTRONIC_INVOICE &&
          t('COMMON:BILLING_METHODS.ELECTRONIC_INVOICE_HELP_TEXT')}
        {values.billingMethod === PAPER_INVOICE &&
          t('COMMON:BILLING_METHODS.PAPER_INVOICE_HELP_TEXT')}
      </P>
    </div>
  )
}

BillingMethodInput.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  disablePaperAddress: PropTypes.bool,
  style: PropTypes.object,
}

export default BillingMethodInput
