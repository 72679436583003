import { registerLocale } from 'react-datepicker'
import { fi, sv, enUS } from 'date-fns/locale'

registerLocale('fi', fi)
registerLocale('sv', sv)
registerLocale('en', enUS)

export const languageToDateFnsLocale = (language) => {
  const map = {
    fi: fi,
    sv: sv,
    // undefined = English
  }

  return map[language]
}
