import React from 'react'
import PropTypes from 'prop-types'
import './Background.scss'

const Background = ({ backgroundColor, children, style }) => (
  <div className="common__background" style={{ ...style, backgroundColor }}>
    {children}
  </div>
)

Background.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Background
