import { useQuery } from '@tanstack/react-query'
import apiStore from '../../../../../common/stores/apiStore'
import benefitsQueryKeys from '../common/benefitsQueryKeys'

export const PENDING_INVITES_PAGE_SIZE = 10
export const benefitInviteesTypes = {
  BUSINESS: 'BUSINESS',
  COMMUTER: 'COMMUTER',
}

const useBenefitInvitesQuery = ({ companyId, params, options }) => {
  return useQuery({
    queryKey: benefitsQueryKeys.invitationsList(companyId, params, options),
    queryFn: async () => {
      return await apiStore.endpoints.invite.getBeneficiaryInvites(
        companyId,
        params,
        options
      )
    },
  })
}

export default useBenefitInvitesQuery
