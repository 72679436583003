import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ExternalLinkButton } from '../../../common/components/Button'
import Page from '../../../common/components/Page'
import { H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import { INVITE_STATUS } from '../../company/constants'

const InvalidLinkScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  return (
    <Page>
      <WhiteBox>
        <H1>{translateTitleByStatus(status, t)}</H1>
        <P>{t('COMMON:PLEASE_CONTACT_YOUR_ADMIN')}</P>
        <ExternalLinkButton secondary href={t('COMMON:HSL_HOME_URL')}>
          {t('COMMON:GO_TO_HSL_FI')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

const translateTitleByStatus = (status, t) => {
  const statusToTranslationMap = {
    [INVITE_STATUS.EXPIRED]: t('COMMON:INVITATION_VALIDITY_ERROR.EXPIRED'),
    [INVITE_STATUS.REVOKED]: t('COMMON:INVITATION_VALIDITY_ERROR.REVOKED'),
    [INVITE_STATUS.ALREADY_ACCEPTED]: t(
      'COMMON:INVITATION_VALIDITY_ERROR.ALREADY_ACCEPTED'
    ),
    [INVITE_STATUS.INCORRECT_ROLE]: t(
      'COMMON:INVITATION_VALIDITY_ERROR.INCORRECT_ROLE'
    ),
    [INVITE_STATUS.TOS_NOT_ACCEPTED]: t(
      'COMMON:INVITATION_VALIDITY_ERROR.TOS_NOT_ACCEPTED'
    ),
  }

  return (
    statusToTranslationMap[status] ??
    t('COMMON:INVITATION_VALIDITY_ERROR.UNKNOWN_REASON')
  )
}

export default observer(InvalidLinkScreen)
