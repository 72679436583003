import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './LabelAndValueList.scss'

const LabelAndValueList = ({
  items,
  className,
  labelClassName,
  valueClassName,
  style,
}) => (
  <div
    className={classnames('common__label-and-value-list', className)}
    style={style}
  >
    {items.map(({ label, value }, index) => (
      <div className="common__label-and-value-list__row" key={index}>
        <div
          className={classnames(
            'common__label-and-value-list__label',
            labelClassName
          )}
        >
          {label}
        </div>
        <div
          className={classnames(
            'common__label-and-value-list__value',
            valueClassName
          )}
        >
          {value}
        </div>
      </div>
    ))}
  </div>
)

LabelAndValueList.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  style: PropTypes.object,
}

export default LabelAndValueList
