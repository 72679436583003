import { makeAutoObservable } from 'mobx'
import apiStore from './apiStore'

class HeaderStore {
  hasAlreadyFetchedBanners = false
  banners = []

  constructor() {
    makeAutoObservable(this)
  }

  fetchBanners = async (language, options) => {
    if (!this.hasAlreadyFetchedBanners) {
      const response = await apiStore.endpoints.banners.getBanners(
        language,
        options
      )

      this.banners = response
      this.hasAlreadyFetchedBanners = true
    }
  }
}

export default new HeaderStore()
