import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import ContainerSpinner from '@hsl-fi/container-spinner'
import Page from '../../../common/components/Page'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, P } from '../../../common/components/Typography'
import { ExternalLinkButton } from '../../../common/components/Button'
import useInvite from '../hooks/useInvite'
import { BENEFIT_TYPE_SEASON_TICKET } from '../../../common/constants'

const VerificationSuccessScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')
  const benefitType = searchParams.get('benefitType')
  const [invite, isFetching] = useInvite(inviteId)

  return (
    <ContainerSpinner visible={isFetching}>
      <Page>
        <WhiteBox>
          <H1>
            {t(
              benefitType === BENEFIT_TYPE_SEASON_TICKET
                ? 'BENEFICIARY:VERIFICATION_SUCCESS_SCREEN.TITLE_FOR_SEASON_TICKET'
                : 'BENEFICIARY:VERIFICATION_SUCCESS_SCREEN.TITLE_FOR_ANNUAL_BALANCE',
              {
                company:
                  (invite ? invite.companyName : '') ||
                  t(
                    'BENEFICIARY:VERIFICATION_SUCCESS_SCREEN.FALLBACK_COMPANY_NAME'
                  ),
              }
            )}
          </H1>
          <P>{t('BENEFICIARY:VERIFICATION_SUCCESS_SCREEN.INSTRUCTIONS_1')}</P>
          {benefitType === BENEFIT_TYPE_SEASON_TICKET && (
            <P>{t('BENEFICIARY:VERIFICATION_SUCCESS_SCREEN.INSTRUCTIONS_2')}</P>
          )}
          <ExternalLinkButton
            href={`/api/beneficiary/strong-authentication?inviteId=${inviteId}&benefitType=${benefitType}`}
          >
            {t('COMMON:CONTINUE')}
          </ExternalLinkButton>
        </WhiteBox>
      </Page>
    </ContainerSpinner>
  )
}

export default observer(VerificationSuccessScreen)
