import { useTranslation } from 'react-i18next'
import { ExternalLinkButton } from '../../../common/components/Button'
import Page from '../../../common/components/Page'
import { H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'

const ApplicationSentScreen = () => {
  const [t] = useTranslation()

  return (
    <Page>
      <WhiteBox>
        <H1>{t('SELF_SERVICE:APPLICATION_SENT_SCREEN.HEADING')}</H1>
        <P>{t('SELF_SERVICE:APPLICATION_SENT_SCREEN.PARAGRAPH_1')}</P>
        <P>{t('SELF_SERVICE:APPLICATION_SENT_SCREEN.PARAGRAPH_2')}</P>
        <ExternalLinkButton href={t('COMMON:HSL_HOME_URL')}>
          {t('SELF_SERVICE:APPLICATION_SENT_SCREEN.RETURN_TO_HSL_FI')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default ApplicationSentScreen
