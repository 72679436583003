import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExternalLinkButton } from '../../../common/components/Button'
import AdminPage from '../../../common/components/AdminPage'
import Hero from '../../../common/components/Hero'
import WideContent from '../../../common/components/WideContent'
import { H2 } from '../../../common/components/Typography'
import { SUPERVISOR_LOGIN_URL } from '../../anonymous/constants'

const SupervisorLandingScreen = () => {
  const [t] = useTranslation()

  return (
    <AdminPage>
      <Hero title={t('COMMON:COMPANY_PORTAL')} />
      <WideContent withVerticalPadding withTopMargin withBottomMargin>
        <H2>{t('SUPERVISOR:LOG_IN_AS_SUPERVISOR')}</H2>
        <ExternalLinkButton
          href={`${SUPERVISOR_LOGIN_URL}?redirectTo=authenticated`}
        >
          {t('COMMON:AUTHENTICATION.LOG_IN')}
        </ExternalLinkButton>
      </WideContent>
    </AdminPage>
  )
}

export default SupervisorLandingScreen
