import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Dialog,
  ModalOverlay,
  Modal as ReactAreaModal,
} from 'react-aria-components'
import { H2 } from '../Typography'
import ModalCloseButton from './ModalCloseButton'

const Position = {
  top: 'top',
  center: 'center',
  right: 'right',
}

const OverlayColor = {
  dark: 'dark',
  transparent: 'transparent',
}

const Modal = ({
  isOpen,
  onClose,
  children,
  heading,
  small,
  position = Position.center,
  overlayColor = OverlayColor.dark,
  marginClassName,
  headingFontSize,
  ariaLabel,
  ...props
}) => {
  return (
    <ModalOverlay
      className={classNames(
        'fixed top-0 left-0 w-screen h-[var(--visual-viewport-height)] z-[999]',
        {
          'bg-black bg-opacity-50 data-[entering]:animate-fadeIn data-[exiting]:animate-fadeOut':
            overlayColor === OverlayColor.dark,
          'desktop:py-xl flex justify-center':
            position === Position.top || position === Position.center,
          'items-center': position === Position.center,
          'items-start': position === Position.top,
        }
      )}
      isDismissable
      isOpen={isOpen}
      onOpenChange={(isOpen) => isOpen || onClose?.()}
    >
      <ReactAreaModal
        className={classNames(
          'bg-white rounded-sm max-h-full w-full flex overflow-auto',
          small ? 'max-w-[400px]' : 'max-w-screen-desktop max-desktop:h-full',
          {
            'absolute right-0 h-full data-[entering]:animate-slideIn data-[exiting]:animate-slideOut':
              position === Position.right,
          }
        )}
        {...props}
      >
        <Dialog
          className={classNames(
            'relative w-full',
            // fix Chrome not showing outline on focused anchors when ancestor has tabIndex="-1"
            'focus-visible:[&_a]:outline focus-visible:[&_a]:outline-2',
            marginClassName || (small ? 'm-lg' : 'm-xl')
          )}
          aria-label={ariaLabel}
        >
          <div
            className={classNames(
              'flex flex-col h-full',
              'only:*:flex only:*:flex-col only:*:h-full' // support body scroll when there is additional wrapper, only child (i.e. form)
            )}
          >
            {Boolean(heading) && (
              <Heading fontSize={headingFontSize}>{heading}</Heading>
            )}
            {children}
          </div>
          {onClose && <ModalCloseButton onClick={onClose} />}
        </Dialog>
      </ReactAreaModal>
    </ModalOverlay>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any,
  heading: PropTypes.string,
  headingFontSize: PropTypes.oneOf(['large', 'medium', 'small']),
  small: PropTypes.bool,
  marginClassName: PropTypes.string,
  position: PropTypes.oneOf(Object.values(Position)),
  overlayColor: PropTypes.oneOf(Object.values(OverlayColor)),
  ariaLabel: PropTypes.string,
}

const Heading = ({ className, ...props }) => (
  <H2
    slot="title"
    className={classNames(
      'mb-lg',
      'pr-xs  mr-[30px]', // don't overflow under button
      className
    )}
    {...props}
  />
)

Heading.propTypes = {
  className: PropTypes.string,
}

const Body = ({ className, ...props }) => (
  <div
    className={classNames(
      'overflow-y-auto',
      '-m-xxs p-xxs', // fix for focus outline cutoff
      className
    )}
    {...props}
  />
)

Body.propTypes = {
  className: PropTypes.string,
}

const Footer = ({ className, ...props }) => (
  <div
    className={classNames('flex flex-wrap gap-sm justify-end mt-lg', className)}
    {...props}
  />
)

Footer.propTypes = {
  className: PropTypes.string,
}

export default Object.assign(Modal, {
  Heading,
  Body,
  Footer,
  Position,
  OverlayColor,
})
