import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './TravelZone.scss'

const TravelZone = ({ zone = '', className, style, ...props }) => (
  <div
    className={classnames('common__travel-zone', className)}
    style={style}
    {...props}
  >
    <div className="common__travel-zone__inner">
      {zone.split('').map((letter) => (
        <span className="common__travel-zone__letter" key={letter}>
          {letter}
        </span>
      ))}
    </div>
  </div>
)

TravelZone.propTypes = {
  zone: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default TravelZone
