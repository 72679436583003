import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './ErrorBox.scss'

const ErrorBox = ({ children, className, style, ...props }) => (
  <div
    role="alert"
    className={classnames('common__error-box', className)}
    style={style}
    {...props}
  >
    {children}
  </div>
)

ErrorBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default ErrorBox
