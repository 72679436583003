import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import Page from '../../../common/components/Page'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, P, A } from '../../../common/components/Typography'
import { ExternalLinkButton } from '../../../common/components/Button'
import {
  SALES_SUPPORT_PHONE_NUMBER,
  SALES_SUPPORT_PHONE_NUMBER_INTL,
} from '../../../common/constants'

const ErrorScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  return (
    <Page>
      <WhiteBox>
        <H1>{t('ANONYMOUS:ERROR_SCREEN.HEADING')}</H1>
        <P>{getBodyTextByHttpStatus(status, t)}</P>
        <P>
          <Trans
            i18nKey="ANONYMOUS:ERROR_SCREEN.CONTACT_CUSTOMER_SUPPORT"
            components={{ a: <A /> }}
            values={{
              email: t('COMMON:SALES_SUPPORT_EMAIL'),
              phoneNumber: SALES_SUPPORT_PHONE_NUMBER,
              phoneNumberIntl: SALES_SUPPORT_PHONE_NUMBER_INTL,
            }}
          />
        </P>
        <ExternalLinkButton href="/authenticated">
          {t('COMMON:BACK_TO_BUSINESS_PORTAL')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

const getBodyTextByHttpStatus = (status, t) => {
  switch (status) {
    case '504':
      return t('ANONYMOUS:ERROR_SCREEN.504')

    default:
      return t('ANONYMOUS:ERROR_SCREEN.PLEASE_TRY_AGAIN_LATER')
  }
}

export default ErrorScreen
