"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountVipType = exports.AccountFormula = exports.AccountStatus = exports.CityBikeDurations = exports.CityBikeAreas = void 0;
var CityBikeAreas;
(function (CityBikeAreas) {
    CityBikeAreas["HELSINKI_ESPOO"] = "helsinki-espoo";
    CityBikeAreas["VANTAA"] = "vantaa";
})(CityBikeAreas = exports.CityBikeAreas || (exports.CityBikeAreas = {}));
var CityBikeDurations;
(function (CityBikeDurations) {
    CityBikeDurations["DAY"] = "day";
    CityBikeDurations["WEEK"] = "week";
    CityBikeDurations["SEASON"] = "season";
})(CityBikeDurations = exports.CityBikeDurations || (exports.CityBikeDurations = {}));
var AccountStatus;
(function (AccountStatus) {
    // HELSINKI-ESPOO STATUS:
    AccountStatus["ACTIVE"] = "active";
    AccountStatus["INACTIVE_NO_VALID_CARD"] = "inactive_no_valid_card";
    AccountStatus["INACTIVE_LOW_BALANCE"] = "inactive_low_balance";
    AccountStatus["INACTIVE_OTHER"] = "inactive_other";
    AccountStatus["INACTIVE_FORBIDDEN"] = "inactive_forbidden";
    AccountStatus["INACTIVE_EXPIRED"] = "inactive_expired";
    AccountStatus["INACTIVE_NO_FORMULA"] = "inactive_no_formula";
    // VANTAA STATUS:
    AccountStatus["NORMAL"] = "Normal";
    AccountStatus["DISABLED"] = "Disabled";
})(AccountStatus = exports.AccountStatus || (exports.AccountStatus = {}));
var AccountFormula;
(function (AccountFormula) {
    AccountFormula["DAY"] = "Day";
    AccountFormula["WEEK"] = "Week";
    AccountFormula["SEASON"] = "Year";
})(AccountFormula = exports.AccountFormula || (exports.AccountFormula = {}));
var AccountVipType;
(function (AccountVipType) {
    AccountVipType[AccountVipType["DAY"] = 91] = "DAY";
    AccountVipType[AccountVipType["WEEK"] = 71] = "WEEK";
    AccountVipType[AccountVipType["SEASON"] = 5] = "SEASON";
})(AccountVipType = exports.AccountVipType || (exports.AccountVipType = {}));
