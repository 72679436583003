"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoSectionDisplayOption = void 0;
var InfoSectionDisplayOption;
(function (InfoSectionDisplayOption) {
    InfoSectionDisplayOption["Info"] = "Info";
    InfoSectionDisplayOption["CallToAction"] = "CallToAction";
    InfoSectionDisplayOption["Accordion"] = "Accordion";
    InfoSectionDisplayOption["LinkList"] = "LinkList";
})(InfoSectionDisplayOption = exports.InfoSectionDisplayOption || (exports.InfoSectionDisplayOption = {}));
