"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.animateScrollTo = exports.animateScrollDown = exports.animateScrollUp = exports.smoothScrollTo = exports.smoothScrollToFirstInvalidField = exports.ScrollSpeed = exports.smoothScrollToTop = void 0;
var smoothScrollToTop = function () {
    return new Promise(function (resolve) {
        var scrollFunction = function () {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll === 0) {
                return resolve(void 0);
            }
            if (currentScroll > 0) {
                window.requestAnimationFrame(scrollFunction);
                window.scrollTo(0, currentScroll - currentScroll / 3);
            }
        };
        scrollFunction();
    });
};
exports.smoothScrollToTop = smoothScrollToTop;
var ScrollSpeed;
(function (ScrollSpeed) {
    ScrollSpeed["FAST"] = "FAST";
    ScrollSpeed["MEDIUM"] = "MEDIUM";
    ScrollSpeed["SLOW"] = "SLOW";
})(ScrollSpeed = exports.ScrollSpeed || (exports.ScrollSpeed = {}));
var speeds = (_a = {},
    _a[ScrollSpeed.FAST] = 100,
    _a[ScrollSpeed.MEDIUM] = 50,
    _a[ScrollSpeed.SLOW] = 20,
    _a);
var smoothScrollToFirstInvalidField = function (_a) {
    var speed = _a.speed, _b = _a.offset, offset = _b === void 0 ? 0 : _b;
    return __awaiter(void 0, void 0, void 0, function () {
        var firstInvalidField, scrollPosition, diff, scrolledToInvalidField;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    firstInvalidField = document.querySelectorAll('[aria-invalid="true"]')[0];
                    //  return if can't find a focusable invalid field
                    if (!firstInvalidField &&
                        typeof firstInvalidField.focus !== 'function' &&
                        typeof firstInvalidField.getBoundingClientRect !== 'function') {
                        return [2 /*return*/];
                    }
                    scrollPosition = firstInvalidField.getBoundingClientRect().top;
                    diff = Math.abs(Math.abs(scrollPosition) - Math.abs(offset));
                    if (diff < 2 || scrollPosition >= offset) {
                        firstInvalidField.focus();
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, new Promise(function (resolve) {
                            var scrollFunction = function () {
                                var currentScroll = firstInvalidField.getBoundingClientRect().top;
                                if (currentScroll === 0) {
                                    resolve(true);
                                }
                                else if (currentScroll < 0) {
                                    var nextScrollStep = currentScroll + speeds[speed];
                                    if (nextScrollStep > 0) {
                                        var revisedScrollStep = speeds[speed] - nextScrollStep;
                                        window.scrollBy(0, -revisedScrollStep);
                                        return resolve(true);
                                    }
                                    window.requestAnimationFrame(scrollFunction);
                                    window.scrollBy(0, -speeds[speed]);
                                }
                                else {
                                    return resolve(false);
                                }
                            };
                            scrollFunction();
                        })];
                case 1:
                    scrolledToInvalidField = _c.sent();
                    if (scrolledToInvalidField) {
                        offset && window.scrollBy(0, offset);
                        firstInvalidField.focus();
                    }
                    else {
                        //  Unable to scroll to invalid field.  Focus on invalid field
                        firstInvalidField.focus();
                    }
                    return [2 /*return*/];
            }
        });
    });
};
exports.smoothScrollToFirstInvalidField = smoothScrollToFirstInvalidField;
var smoothScrollTo = function (ref, callBack, speed, offset) {
    if (offset === void 0) { offset = 0; }
    return __awaiter(void 0, void 0, void 0, function () {
        var scrollPosition, diff, scrolledToRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    scrollPosition = ref.current.getBoundingClientRect().top;
                    diff = Math.abs(Math.abs(scrollPosition) - Math.abs(offset));
                    if (diff < 2 || scrollPosition >= offset) {
                        return [2 /*return*/];
                    }
                    callBack(true);
                    return [4 /*yield*/, new Promise(function (resolve) {
                            var scrollFunction = function () {
                                var currentScroll = ref.current.getBoundingClientRect().top;
                                if (currentScroll === 0) {
                                    resolve(true);
                                }
                                else if (currentScroll < 0) {
                                    var nextScrollStep = currentScroll + speeds[speed];
                                    if (nextScrollStep > 0) {
                                        var revisedScrollStep = speeds[speed] - nextScrollStep;
                                        window.scrollBy(0, -revisedScrollStep);
                                        return resolve(true);
                                    }
                                    window.requestAnimationFrame(scrollFunction);
                                    window.scrollBy(0, -speeds[speed]);
                                }
                                else {
                                    return resolve(false);
                                }
                            };
                            scrollFunction();
                        })];
                case 1:
                    scrolledToRef = _a.sent();
                    if (scrolledToRef) {
                        offset && window.scrollBy(0, offset);
                    }
                    else {
                        //  Unable to scroll to ref.  Go to top of page
                        exports.smoothScrollToTop();
                    }
                    callBack(false);
                    return [2 /*return*/];
            }
        });
    });
};
exports.smoothScrollTo = smoothScrollTo;
var getSpeedWithEasing = function (currentOffset, speed) {
    var isNegative = currentOffset < 0, offset = isNegative ? currentOffset * -1 : currentOffset;
    var speedWithEasing = speed;
    if (offset >= 300 && offset < 500) {
        speedWithEasing = speed * 0.7;
    }
    if (offset >= 100 && offset < 300) {
        speedWithEasing = speed * 0.45;
    }
    if (offset >= 50 && offset < 100) {
        speedWithEasing = speed * 0.3;
    }
    if (offset >= 20 && offset < 50) {
        speedWithEasing = speed * 0.15;
    }
    if (offset > 0 && offset < 20) {
        speedWithEasing = speed * 0.1;
    }
    return isNegative ? speedWithEasing / -1 : speedWithEasing;
};
var animateScrollUp = function (_a) {
    var ref = _a.ref, speed = _a.speed, _b = _a.offset, offset = _b === void 0 ? 0 : _b, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        var scrolledToRef;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (callback) {
                        callback(true);
                    }
                    return [4 /*yield*/, new Promise(function (resolve) {
                            var scrollFunction = function () {
                                var currentScroll = ref.current.getBoundingClientRect().top, speedWithEasing = getSpeedWithEasing(currentScroll, speed);
                                if (currentScroll === 0) {
                                    resolve(true);
                                }
                                else if (currentScroll < 0) {
                                    var nextScrollStep = currentScroll + speed;
                                    if (nextScrollStep >= offset) {
                                        window.scrollBy(0, -offset);
                                        return resolve(true);
                                    }
                                    window.requestAnimationFrame(scrollFunction);
                                    window.scrollBy(0, speedWithEasing);
                                }
                            };
                            scrollFunction();
                        })];
                case 1:
                    scrolledToRef = _c.sent();
                    if (scrolledToRef) {
                        offset && window.scrollBy(0, offset);
                    }
                    else {
                        //  Unable to scroll to ref.  Go to top of page
                        exports.smoothScrollToTop();
                    }
                    if (callback) {
                        callback(false);
                    }
                    return [2 /*return*/];
            }
        });
    });
};
exports.animateScrollUp = animateScrollUp;
var animateScrollDown = function (_a) {
    var ref = _a.ref, speed = _a.speed, _b = _a.offset, offset = _b === void 0 ? 0 : _b, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (callback) {
                        callback(true);
                    }
                    return [4 /*yield*/, new Promise(function (resolve) {
                            var previousScroll = 0;
                            var scrollFunction = function () {
                                var currentScroll = ref.current.getBoundingClientRect().top, speedWithEasing = getSpeedWithEasing(currentScroll, speed);
                                if (currentScroll === 0) {
                                    resolve(true);
                                }
                                else if (currentScroll > 0) {
                                    if (currentScroll === previousScroll) {
                                        // case 1: scroll cannot proceed further, meaning we've reached the end of the page
                                        // case 2: scroll is stuck in the same position for some other reason
                                        return resolve(true);
                                    }
                                    else {
                                        previousScroll = currentScroll;
                                        var nextScrollStep = currentScroll - speed;
                                        if (nextScrollStep <= offset) {
                                            window.scrollBy(0, offset);
                                            return resolve(true);
                                        }
                                        window.requestAnimationFrame(scrollFunction);
                                        window.scrollBy(0, +speedWithEasing);
                                    }
                                }
                                else {
                                    return resolve(false);
                                }
                            };
                            scrollFunction();
                        })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
};
exports.animateScrollDown = animateScrollDown;
var animateScrollTo = function (_a) {
    var ref = _a.ref, speed = _a.speed, _b = _a.offset, offset = _b === void 0 ? 0 : _b, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        var speeds, scrollPosition, scrollSpeed, scrollMethod;
        var _c;
        return __generator(this, function (_d) {
            speeds = (_c = {},
                _c[ScrollSpeed.FAST] = 100,
                _c[ScrollSpeed.MEDIUM] = 50,
                _c[ScrollSpeed.SLOW] = 20,
                _c);
            scrollPosition = ref.current.offsetTop, scrollSpeed = speeds[speed], scrollMethod = window.scrollY > scrollPosition ? exports.animateScrollUp : exports.animateScrollDown;
            scrollMethod({ ref: ref, speed: scrollSpeed, offset: offset, callback: callback });
            return [2 /*return*/];
        });
    });
};
exports.animateScrollTo = animateScrollTo;
