import { useMutation } from '@tanstack/react-query'
import apiStore from '../../../../common/stores/apiStore'

const useSendNewCompanyApplicationMutation = () => {
  return useMutation({
    mutationFn: async ({ payload }) => {
      return await apiStore.endpoints.company.sendNewCompanyApplication(payload)
    },
  })
}

export default useSendNewCompanyApplicationMutation
