"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addImagePreset = void 0;
var content_delivery_api_types_1 = require("@hsl-fi/content-delivery-api-types");
var addImagePreset = function (imageUrl, preset) {
    if (preset === void 0) { preset = content_delivery_api_types_1.ImagePreset.DEFAULT; }
    if (!imageUrl || imageUrl === null) {
        return null;
    }
    imageUrl.includes('?') ? (imageUrl += '&') : (imageUrl += '?');
    return (imageUrl += "preset=" + preset);
};
exports.addImagePreset = addImagePreset;
