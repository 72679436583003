"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Features = void 0;
var Features;
(function (Features) {
    Features["USER_LOGIN"] = "userLogin";
    Features["HEADER_NOTIFICATIONS"] = "headerNotifications";
    Features["NICKNAME"] = "nickName";
    Features["SHARED_LOCAL_STORAGE"] = "sharedLocalStorage";
    Features["COMMENTING"] = "commenting";
    Features["CITY_BIKES"] = "cityBikes";
    Features["NEW_MESSAGE_SETTINGS"] = "newMessageSettings";
    Features["DELETE_HSL_ACCOUNT"] = "deleteHslAccount";
    Features["SEASON_TICKET_CAMPAIGN"] = "seasonTicketCampaign";
})(Features = exports.Features || (exports.Features = {}));
