import { useState, useEffect } from 'react'
import { useIsConsentGiven } from '@hsl-fi/cookies'

export const useCookieConsent = () => {
  const [fakeConsentValue, setFakeConsentValue] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFakeConsentValue(true) // simulate delayed value on localhost
    }, 100)
  }, [])

  const preferences = {
    [COOKIE_CATEGORY_NECESSARY]: isLocalhost
      ? fakeConsentValue
      : useIsConsentGiven(COOKIE_CATEGORY_NECESSARY),
    [COOKIE_CATEGORY_FUNCTIONAL]: isLocalhost
      ? fakeConsentValue
      : useIsConsentGiven(COOKIE_CATEGORY_FUNCTIONAL),
    [COOKIE_CATEGORY_MARKETING]: isLocalhost
      ? fakeConsentValue
      : useIsConsentGiven(COOKIE_CATEGORY_MARKETING),
    [COOKIE_CATEGORY_STATISTIC]: isLocalhost
      ? fakeConsentValue
      : useIsConsentGiven(COOKIE_CATEGORY_STATISTIC),
    [COOKIE_CATEGORY_UNCLASSIFIED]: isLocalhost
      ? fakeConsentValue
      : useIsConsentGiven(COOKIE_CATEGORY_UNCLASSIFIED),
  }

  return preferences
}

export const useIsConsentGivenForNecessaryCookies = () => {
  const preferences = useCookieConsent()

  return preferences[COOKIE_CATEGORY_NECESSARY]
}

export const useIsConsentGivenForFunctionalCookies = () => {
  const preferences = useCookieConsent()

  return preferences[COOKIE_CATEGORY_FUNCTIONAL]
}

export const setCookieConsentLanguage = (language) => {
  if (window.CookieInformation) {
    document
      .querySelector('#CookieConsent')
      .setAttribute('data-culture', language)

    window.CookieInformation.loadConsent()
  }
}

const isLocalhost = process.env.NODE_ENV !== 'production'

export const COOKIE_CATEGORY_NECESSARY = 'cookie_cat_necessary'
export const COOKIE_CATEGORY_FUNCTIONAL = 'cookie_cat_functional'
export const COOKIE_CATEGORY_MARKETING = 'cookie_cat_marketing'
export const COOKIE_CATEGORY_STATISTIC = 'cookie_cat_statistic'
export const COOKIE_CATEGORY_UNCLASSIFIED = 'cookie_cat_unclassified'
