import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Autocomplete from './Autocomplete'
import i18n from '../i18n'
import uiMessageStore from '../stores/uiMessageStore'
import apiStore from '../stores/apiStore'

const AutocompleteAddress = ({ value, ...props }) => (
  <Autocomplete
    getSuggestions={searchAddress}
    convertSuggestionsToDropdownOptions={convertSuggestionsToDropdownOptions}
    value={value}
    {...props}
  />
)

AutocompleteAddress.propTypes = {
  value: PropTypes.string,
}

export const searchAddress = async (text) => {
  try {
    if (!text) {
      return
    }

    return await apiStore.endpoints.digitransit.addressSearch(text)
  } catch (e) {
    uiMessageStore.addError(i18n.t('COMMON:ERRORS.SERVER_ERROR'), e)
  }
}

const convertSuggestionsToDropdownOptions = (response) =>
  response.features.map((feature) => formatAddress(feature))

const formatAddress = (feature) => ({
  label: `${feature.properties.name}, ${[
    feature.properties.postalcode,
    feature.properties.localadmin,
  ].join(' ')}`,
  city: feature.properties.localadmin,
  postalCode: feature.properties.postalcode,
  street: feature.properties.name,
  coordinates: feature.geometry.coordinates,
})

export default observer(AutocompleteAddress)
