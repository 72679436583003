import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

// Enable Sentry in a deployed Azure environment only (dev/UAT/production)
const SENTRY_DSN =
  process.env.NODE_ENV === 'production'
    ? 'https://38ed0ef295664ad48f66f4ddc4ffde51@o26423.ingest.sentry.io/5503906'
    : undefined

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: process.env.REACT_APP_ENV || 'local',
  ignoreErrors: [
    'AbortError',
    'fbq is not defined',
    '_paq is not defined',
    "Can't find variable: fbq",
  ],
})

export const captureException = (exception) =>
  Sentry.captureException({ ...exception })
