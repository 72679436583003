import PropTypes from 'prop-types'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '../../../../common/components/Button'
import CheckboxWithLabel from '../../../../common/components/CheckboxWithLabel'
import FormErrors from '../../../../common/components/FormErrors'
import Modal from '../../../../common/components/Modal/Modal'
import { A, P } from '../../../../common/components/Typography'
import {
  SALES_SUPPORT_PHONE_NUMBER,
  SALES_SUPPORT_PHONE_NUMBER_INTL,
} from '../../../../common/constants'
import useCompanyId from '../../../../common/hooks/useCompanyId'
import useAcceptTOSMutation from './useAcceptTOSMutation'

const AcceptTOSModal = ({ isOpen, setIsTosAccepted }) => {
  const [t] = useTranslation()
  const companyId = useCompanyId()
  const [termsOfService, setTermsOfService] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [errors, setErrors] = useState({})
  const { mutateAsync: acceptTOS, isPending: isAcceptingTos } =
    useAcceptTOSMutation({ companyId })

  const next = async () => {
    const errorsTemp = {}

    if (!termsOfService) {
      errorsTemp.termsOfService = t('COMMON:REQUIRED_FIELD_X', {
        field: t('COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.HSL_TERMS_OF_SERVICE'),
      })
    }

    if (!privacyPolicy) {
      errorsTemp.privacyPolicy = t('COMMON:REQUIRED_FIELD_X', {
        field: t('COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.HSL_PRIVACY_POLICY'),
      })
    }

    setErrors(errorsTemp)

    if (Object.keys(errorsTemp).length > 0) {
      return
    }

    await acceptTOS()
    setIsTosAccepted(true)
  }

  return (
    <Modal isOpen={isOpen}>
      <Modal.Heading>
        {t('COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.FIRST_TIME_HEADING')}
      </Modal.Heading>
      <Modal.Body>
        <P className="mt-0">
          <Trans
            i18nKey="COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.PRIVACY_POLICY"
            components={{ a: <A /> }}
            values={{
              url: t(
                'COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.PRIVACY_POLICY_URL'
              ),
            }}
          />
        </P>
        <P>
          <Trans
            i18nKey="COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.FIRST_TIME_BODY_2"
            components={{ a: <A /> }}
            values={{
              email: t('COMMON:SALES_SUPPORT_EMAIL'),
              phoneNumber: SALES_SUPPORT_PHONE_NUMBER,
              phoneNumberIntl: SALES_SUPPORT_PHONE_NUMBER_INTL,
            }}
          />
        </P>
        <CheckboxWithLabel
          className="mb-md mt-xxl"
          checked={termsOfService}
          onClick={() => setTermsOfService(!termsOfService)}
          label={
            <Trans
              i18nKey="COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.HSL_TERMS_OF_SERVICE_CHECKBOX_LABEL"
              components={{
                a: (
                  <A
                    fontSize="small"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
              values={{
                url: t('COMMON:CBS_TERMS_OF_SERVICE_URL'),
              }}
            />
          }
          hasError={Boolean(errors.termsOfService)}
          style={{ marginBottom: '15px' }}
        />
        <CheckboxWithLabel
          checked={privacyPolicy}
          onClick={() => setPrivacyPolicy(!privacyPolicy)}
          label={
            <Trans
              i18nKey="COMPANY:REVIEW_TERMS_OF_SERVICE_SCREEN.HSL_PRIVACY_POLICY_CHECKBOX_LABEL"
              components={{
                a: (
                  <A
                    fontSize="small"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
              values={{
                url: t('COMMON:HSL_PRIVACY_STATEMENT_URL'),
              }}
            />
          }
          hasError={Boolean(errors.privacyPolicy)}
        />
        {Object.keys(errors).length > 0 && (
          <FormErrors
            className="mt-xl"
            errors={Object.keys(errors).map((key) => errors[key])}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={next} loading={isAcceptingTos}>
          {t('COMMON:CONTINUE')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AcceptTOSModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsTosAccepted: PropTypes.func,
}

export default AcceptTOSModal
