// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes _2Ie-mtMqWW0UpebuMUr9Jn {\n  from {\n    background-position: 200% 0;\n  }\n  to {\n    background-position: -200% 0;\n  }\n}\n._3SQf_hWCuoV4WYriByCh6H {\n  position: relative;\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n\n._2AZIGBL_F4whwV-8p23z5C {\n  background-size: 200% 100%;\n  background-repeat: no-repeat;\n  animation: _2Ie-mtMqWW0UpebuMUr9Jn 2s infinite linear;\n  transition: opacity 0.5s;\n  opacity: 0;\n  pointer-events: none;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n.Fjl_-sZtU3fcBmCj36o6e {\n  background-color: #ffffff;\n  background-image: linear-gradient(90deg, #ffffff 0%, #eef1f3 50%, #ffffff 100%);\n}\n._29CEjjOqBj3OGFxxRWcRq2 {\n  background-color: #eef1f3;\n  background-image: linear-gradient(90deg, #eef1f3 0%, #dddddd 50%, #eef1f3 100%);\n}\n._15opyqAIFeQmvIsgVdPNEg {\n  opacity: 1;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shimmerContainer": "_3SQf_hWCuoV4WYriByCh6H",
	"shimmer": "_2AZIGBL_F4whwV-8p23z5C",
	"shimmerTransition": "_2Ie-mtMqWW0UpebuMUr9Jn",
	"shimmerWhite": "Fjl_-sZtU3fcBmCj36o6e",
	"shimmerGrey": "_29CEjjOqBj3OGFxxRWcRq2",
	"shimmerVisible": "_15opyqAIFeQmvIsgVdPNEg"
};
module.exports = ___CSS_LOADER_EXPORT___;
