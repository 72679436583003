import { makeAutoObservable } from 'mobx'

class UiMessageStore {
  messages = {}

  constructor() {
    makeAutoObservable(this)
  }

  addMessage = (message) => {
    if (!this.messages[message]) {
      this.messages[message] = MESSAGE_TYPE_INFO

      window.setTimeout(() => this.clear(message), MESSAGE_DURATION_MS)
    }
  }

  addError = (message, error) => {
    if (error?.name === 'AbortError') {
      return
    }

    if (!this.messages[message]) {
      this.messages[message] = MESSAGE_TYPE_ERROR

      window.setTimeout(() => this.clear(message), MESSAGE_DURATION_MS)
    }
  }

  clear = (message) => {
    const messages = { ...this.messages }

    delete messages[message]

    this.messages = messages
  }
}

export const MESSAGE_TYPE_INFO = 'INFO'
export const MESSAGE_TYPE_ERROR = 'ERROR'

const MESSAGE_DURATION_MS = 3500

export default new UiMessageStore()
