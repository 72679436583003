import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import './SimpleTable.scss'

const SimpleTable = ({
  fields,
  rows,
  gridTemplateColumnsStyle,
  className,
  style,
}) => {
  const [t] = useTranslation()
  const gridTemplateColumns =
    gridTemplateColumnsStyle || `repeat(${fields.length}, minmax(0, 1fr))`

  return (
    <div
      className={classnames('common__simple-table', className)}
      style={style}
    >
      <div className="common__simple-table__header">
        <div
          className="common__simple-table__grid"
          style={{ gridTemplateColumns }}
        >
          {fields.map(({ fieldName, label }) => (
            <div key={fieldName} className="common__simple-table__column">
              {label}
            </div>
          ))}
        </div>
      </div>
      <div className="common__simple-table__rows">
        {!rows.length && (
          <div className="common__simple-table__empty-table">
            {t('COMMON:TABLE.NO_RESULTS')}
          </div>
        )}
        {rows.map((row, index) => (
          <div
            key={index}
            className={classnames('common__simple-table__row', row.className)}
            style={row.style}
          >
            <div
              className="common__simple-table__grid"
              style={{ gridTemplateColumns }}
            >
              {fields.map(({ fieldName, label }) => (
                <div
                  className="common__simple-table__column-group"
                  key={fieldName}
                >
                  <div className="common__simple-table__mobile-heading">
                    {label}
                  </div>
                  <div className="common__simple-table__column">
                    {row.fields[fieldName]}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

SimpleTable.propTypes = {
  fields: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  gridTemplateColumnsStyle: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default SimpleTable
