import React, { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import ContainerSpinner from '@hsl-fi/container-spinner'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import apiStore from '../../../common/stores/apiStore'
import Page from '../../../common/components/Page'
import { H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import AutocompleteAddress, {
  searchAddress,
} from '../../../common/components/AutocompleteAddress'
import { formatAddressOnSingleLine } from '../../../common/format'
import { Button } from '../../../common/components/Button'
import Map, { flipCoordinates } from '../../../common/components/Map'
import FormErrors from '../../../common/components/FormErrors'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import { isDomicileWithinHsl } from '../../../common/helpers'
import {
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
} from '../../../common/constants'
import { INVITE_STATUS } from '../../company/constants'
import { captureException } from '../../../common/errorTracking'

const blankAddress = {
  streetAddress: '',
  postalCode: '',
  city: '',
}

const StrongAuthenticationSuccessScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')
  const benefitType = searchParams.get('benefitType')
  const [isLoading, setIsLoading] = useState(true)
  const [beneficiaryAddress, setBeneficiaryAddress] = useState(blankAddress)
  const [companyAddress, setCompanyAddress] = useState(blankAddress)
  const [profile, setProfile] = useState()
  const [errors, setErrors] = useState({})
  const [assignedZone, setAssignedZone] = useState()
  const [inviteStatus, setInviteStatus] = useState()

  const [beneficiaryAddressCoordinates, setBeneficiaryAddressCoordinates] =
    useState()
  const [companyAddressCoordinates, setCompanyAddressCoordinates] = useState()

  const [beneficiaryAddressInput, setBeneficiaryAddressInput] = useState('')
  const [companyAddressInput, setCompanyAddressInput] = useState('')

  const flippedBeneficiaryAddressCoordinates = useMemo(
    () =>
      beneficiaryAddressCoordinates
        ? flipCoordinates(beneficiaryAddressCoordinates)
        : undefined,
    [beneficiaryAddressCoordinates]
  )
  const flippedCompanyAddressCoordinates = useMemo(
    () =>
      companyAddressCoordinates
        ? flipCoordinates(companyAddressCoordinates)
        : undefined,
    [companyAddressCoordinates]
  )

  const [hasAcceptedInvite, setHasAcceptedInvite] = useState(false)
  const [hasAcceptInviteFailed, setHasAcceptInviteFailed] = useState(false)

  const [hasInviteValidationFailed, setHasInviteValidationFailed] =
    useState(false)

  const markers = useMemo(
    () =>
      [
        {
          position: flippedBeneficiaryAddressCoordinates,
          label: t(
            'BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.YOUR_HOME_ADDRESS'
          ),
        },
        {
          position: flippedCompanyAddressCoordinates,
          label: t(
            'BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.YOUR_COMPANY_ADDRESS'
          ),
        },
      ].filter((marker) => Boolean(marker.position)),
    [flippedBeneficiaryAddressCoordinates, flippedCompanyAddressCoordinates, t]
  )

  useEffect(() => {
    const initialize = async () => {
      try {
        // Submit the accept request right away if the benefit type is annual balance
        if (
          benefitType === BENEFIT_TYPE_ANNUAL_BALANCE ||
          benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE
        ) {
          await acceptInviteWithAnnualBalance()

          return
        }

        setIsLoading(true)

        // Make sure the user's municipality is within the HSL/neighboring area
        const { status } =
          await apiStore.endpoints.invite.validateBeneficiaryInvite(inviteId)

        setInviteStatus(status)

        if (status !== INVITE_STATUS.VALID) {
          setIsLoading(false)
          return
        }

        const [inviteResponse, profileResponse] = await Promise.all([
          apiStore.endpoints.invite.getInviteById(inviteId),
          apiStore.endpoints.user.getProfile(),
        ])

        // Prevent beneficiaries from re-submitting their addresses after completing the flow once
        if (inviteResponse.accepted) {
          setAssignedZone(inviteResponse.zone)
          setHasAcceptedInvite(true)
        }

        setProfile(profileResponse)

        const [companyAddress] = inviteResponse.companyAddresses
        const [beneficiaryAddress] = profileResponse.legalAddresses

        const [companyAddressData, beneficiaryAddressData] = await Promise.all([
          searchAddress(
            formatAddressOnSingleLine(
              companyAddress?.streetAddress,
              companyAddress.postalCode,
              companyAddress.locality
            )
          ),
          searchAddress(
            formatAddressOnSingleLine(
              beneficiaryAddress?.streetAddress,
              beneficiaryAddress?.postalCode,
              beneficiaryAddress?.locality
            )
          ),
        ])

        if (beneficiaryAddress) {
          setBeneficiaryAddressInput(
            formatAddressOnSingleLine(
              beneficiaryAddress?.streetAddress,
              beneficiaryAddress?.postalCode,
              beneficiaryAddress?.locality
            )
          )

          const address = {
            streetAddress: beneficiaryAddress?.streetAddress,
            postalCode: beneficiaryAddress?.postalCode,
            city: beneficiaryAddress?.locality,
          }

          setBeneficiaryAddress(address)

          setBeneficiaryAddressCoordinates(
            beneficiaryAddressData.features[0]?.geometry?.coordinates
          )
        }

        if (companyAddress) {
          // Search for a matching address from Digitransit since HSL ID's company objects do not currently contain postal codes.
          // This extra step can be removed once it has been confirmed that all companies in production have valid postal code values.
          const [firstCompanyAddress] = companyAddressData.features
          const companyStreetAddress = [
            firstCompanyAddress?.properties?.street,
            firstCompanyAddress?.properties?.housenumber,
          ].join(' ')
          const companyPostalCode = firstCompanyAddress?.properties?.postalcode
          const companyCity = firstCompanyAddress?.properties?.localadmin

          setCompanyAddressInput(
            formatAddressOnSingleLine(
              companyStreetAddress,
              companyPostalCode,
              companyCity
            )
          )

          const address = {
            streetAddress: companyStreetAddress,
            postalCode: companyPostalCode,
            city: companyCity,
          }

          setCompanyAddress(address)

          setCompanyAddressCoordinates(
            companyAddressData?.features[0]?.geometry?.coordinates
          )
        }

        setIsLoading(false)
      } catch (e) {
        captureException({
          message: 'Beneficiary flow finalization failed',
          extra: {
            error: e,
          },
        })

        if (e.status === 403) {
          setHasInviteValidationFailed(true)
        } else {
          uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)
        }
      }
    }

    initialize()
  }, [inviteId, t])

  const acceptInviteWithSeasonTicket = async () => {
    const errors = validateForm({ beneficiaryAddress, companyAddress }, t)

    setErrors(errors)

    if (hasErrors(errors)) {
      return
    }

    try {
      setIsLoading(true)

      const payload = {
        homeAddress: {
          streetAddress: beneficiaryAddress.streetAddress,
          postalCode: beneficiaryAddress.postalCode,
          locality: beneficiaryAddress.city,
        },
        workAddress: {
          streetAddress: companyAddress.streetAddress,
          postalCode: companyAddress.postalCode,
          locality: companyAddress.city,
        },
      }

      const response = await apiStore.endpoints.invite.acceptInvite(
        inviteId,
        payload
      )

      setAssignedZone(response.zoneResult.zone)

      setHasAcceptedInvite(true)
    } catch (e) {
      setHasAcceptInviteFailed(true)

      captureException({
        message: 'Beneficiary flow finalization with season ticket failed',
        extra: {
          error: e,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const acceptInviteWithAnnualBalance = async () => {
    try {
      setIsLoading(true)

      await apiStore.endpoints.invite.acceptInvite(inviteId, {})

      setHasAcceptedInvite(true)
    } catch (e) {
      setHasAcceptInviteFailed(true)

      captureException({
        message: 'Beneficiary flow finalization with annual benefit failed',
        extra: {
          error: e,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (hasAcceptedInvite) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.BENEFICIARY_SUCCESS_SCREEN}
        query={{ inviteId, benefitType, zone: assignedZone }}
      />
    )
  }

  if (!isLoading && inviteStatus !== INVITE_STATUS.VALID) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.INVALID_LINK}
        query={{ status: inviteStatus }}
      />
    )
  }

  if (hasAcceptInviteFailed) {
    return <Redirect route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR} />
  }

  if (hasInviteValidationFailed) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR}
        query={{ reason: 'outside-hsl-zone' }}
      />
    )
  }

  if (profile) {
    if (!isDomicileWithinHsl(profile.domicileClasses)) {
      return (
        <Redirect
          route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR}
          query={{ reason: 'outside-hsl-zone' }}
        />
      )
    }
  }

  return (
    <ContainerSpinner visible={isLoading}>
      <Page>
        <WhiteBox style={{ marginBottom: '30px' }}>
          <H1>
            {t('BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.HEADING')}
          </H1>
          <P>
            {t('BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.DESCRIPTION')}
          </P>
          <AutocompleteAddress
            value={beneficiaryAddressInput}
            label={t(
              'BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.YOUR_HOME_ADDRESS'
            )}
            hasError={Boolean(errors.beneficiaryStreetAddress)}
            onType={(value) => {
              setBeneficiaryAddressInput(value)
              setBeneficiaryAddress(blankAddress)
            }}
            onSelect={(option) => {
              setBeneficiaryAddressInput(
                formatAddressOnSingleLine(
                  option.street,
                  option.postalCode,
                  option.city
                )
              )

              setBeneficiaryAddress({
                streetAddress: option.street,
                postalCode: option.postalCode,
                city: option.city,
              })

              setBeneficiaryAddressCoordinates(option.coordinates)
            }}
            onDismiss={() => {
              setBeneficiaryAddressInput('')
              setBeneficiaryAddress(blankAddress)
              setBeneficiaryAddressCoordinates(undefined)
            }}
          />
          <AutocompleteAddress
            value={companyAddressInput}
            label={t(
              'BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.YOUR_COMPANY_ADDRESS'
            )}
            hasError={Boolean(errors.companyStreetAddress)}
            onType={(value) => {
              setCompanyAddressInput(value)
              setCompanyAddress(blankAddress)
            }}
            onSelect={(option) => {
              setCompanyAddressInput(
                formatAddressOnSingleLine(
                  option.street,
                  option.postalCode,
                  option.city
                )
              )

              setCompanyAddress({
                streetAddress: option.street,
                postalCode: option.postalCode,
                city: option.city,
              })

              setCompanyAddressCoordinates(option.coordinates)
            }}
            onDismiss={() => {
              setCompanyAddressInput('')
              setCompanyAddress(blankAddress)
              setCompanyAddressCoordinates(undefined)
            }}
          />
          {flippedBeneficiaryAddressCoordinates && (
            <Map
              position={flippedBeneficiaryAddressCoordinates}
              markers={markers}
              style={{
                height: '370px',
                width: 'auto',
                marginBottom: '30px',
                zIndex: 0,
              }}
            />
          )}
          {hasErrors(errors) && (
            <FormErrors
              errors={Object.keys(errors).map((key) => errors[key])}
              style={{ marginBottom: '30px' }}
            />
          )}
          <Button onClick={acceptInviteWithSeasonTicket} loading={isLoading}>
            {t('COMMON:CONTINUE')}
          </Button>
        </WhiteBox>
      </Page>
    </ContainerSpinner>
  )
}

const hasErrors = (errors) => Boolean(Object.keys(errors).length)

const validateForm = (form, t) => {
  const errors = {}

  if (
    !form.beneficiaryAddress.streetAddress ||
    !form.beneficiaryAddress.postalCode ||
    !form.beneficiaryAddress.city
  ) {
    errors.beneficiaryStreetAddress = t('COMMON:INVALID_ADDRESS', {
      field: t(
        'BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.YOUR_HOME_ADDRESS'
      ),
    })
  }

  if (
    !form.companyAddress.streetAddress ||
    !form.companyAddress.postalCode ||
    !form.companyAddress.city
  ) {
    errors.companyStreetAddress = t('COMMON:INVALID_ADDRESS', {
      field: t(
        'BENEFICIARY:STRONG_AUTHENTICATION_SUCCESS_SCREEN.YOUR_COMPANY_ADDRESS'
      ),
    })
  }

  return errors
}

export default observer(StrongAuthenticationSuccessScreen)
