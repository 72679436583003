"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.relativeSizes = void 0;
exports.relativeSizes = {
    bearer: 0.969,
    'bicycle-station': 1.08,
    briefcase: 0.939,
    bubble: 0.891,
    citybike: 1.05,
    info: 0.88,
    'customer-service': 1.3,
    'day-ticket': 1.1,
    facebook: 0.9199,
    'hsl-card-exclamation': 1.155,
    'info-circle': 0.891,
    instagram: 0.7143,
    'light-bulb': 0.969,
    'linked-in': 0.7143,
    'missing-items': 1.17,
    'mobile-ticket': 0.96875,
    money: 0.969,
    person: 0.813,
    route: 1.031,
    star: 0.891,
    train: 1.17,
    traveller: 0.889,
    twitter: 0.8833,
    'u-train': 1.14,
    'war-invalids': 0.859,
    'war-veterans': 0.891,
    warning: 0.939,
    planning: 1.15,
    files: 0.9,
    'local-bus': 0.92,
    'air-condition': 0.92,
    voice: 0.92
};
exports.default = exports.relativeSizes;
