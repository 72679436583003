const classNames = require('classnames')
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg'
import { Button } from '../Button'

const ModalCloseButton = ({ className, ...props }) => {
  const [t] = useTranslation()
  return (
    <Button
      transparent
      compact
      className={classNames(
        'size-[30px] absolute right-0 top-0 bg-white',
        className
      )}
      aria-label={t('COMMON:CLOSE')}
      {...props}
    >
      <CloseIcon className="flex" aria-hidden />
    </Button>
  )
}

ModalCloseButton.propTypes = {
  className: PropTypes.string,
}

export default ModalCloseButton
