// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2fXO9njDYPn5LpWuPlU0kJ {\n  position: absolute !important;\n  height: 1px;\n  width: 1px;\n  overflow: hidden;\n  clip: rect(1px 1px 1px 1px);\n  clip: rect(1px, 1px, 1px, 1px);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenReaderOnly": "_2fXO9njDYPn5LpWuPlU0kJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
