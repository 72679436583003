import { BENEFIT_TYPE_SEASON_TICKET } from '../../../common/constants'
import { TICKET_TYPES } from '../constants'

const translateTicketType = (purchase, t) => {
  if (
    purchase.ticketType === TICKET_TYPES.SEASON &&
    purchase.benefitType === BENEFIT_TYPE_SEASON_TICKET
  ) {
    return t('COMPANY:TICKET_TYPE.SEASON_TICKET_ONGOING')
  }

  const ticketTypeToTranslationMap = {
    [TICKET_TYPES.SINGLE]: t('COMPANY:TICKET_TYPE.SINGLE_TICKET'),
    [TICKET_TYPES.ZONE_EXTENSION]: t('COMPANY:TICKET_TYPE.ZONE_EXTENSION'),
    [TICKET_TYPES.DAY]: t('COMPANY:TICKET_TYPE.DAY_TICKET', {
      count: purchase.duration,
    }),
    [TICKET_TYPES.SEASON]: t('COMPANY:TICKET_TYPE.SEASON_TICKET', {
      count: purchase.duration,
    }),
    [TICKET_TYPES.SERIAL]: t('COMPANY:TICKET_TYPE.SERIAL'),
    [TICKET_TYPES.GENERAL]: t('COMPANY:TICKET_TYPE.GENERAL'),
    [TICKET_TYPES.UNDEFINED]: t('COMPANY:TICKET_TYPE.UNDEFINED'),
  }

  return ticketTypeToTranslationMap[purchase.ticketType]
}

export default translateTicketType
