import { validateEmail } from './validate'

const validateEmailAndToken = (form, t) => {
  const errors = {}

  if (!validateEmail(form.workEmail)) {
    errors.workEmail = t('COMMON:INVALID_VALUE_X', {
      field: t('BENEFICIARY:VERIFICATION_SCREEN.WORK_EMAIL'),
    })
  }

  if (!form.token || form.token.length < 4) {
    errors.token = t('COMMON:INVALID_VALUE_X', {
      field: t('BENEFICIARY:VERIFICATION_SCREEN.TOKEN'),
    })
  }

  return errors
}

export default validateEmailAndToken
