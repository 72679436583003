// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2l3-VJzFkZItN8rkJbUSeG {\n  -webkit-animation: _1zC1Ht6eJWJJyZdbOs3xEf 500ms infinite linear;\n  -moz-animation: _1zC1Ht6eJWJJyZdbOs3xEf 500ms infinite linear;\n  -ms-animation: _1zC1Ht6eJWJJyZdbOs3xEf 500ms infinite linear;\n  -o-animation: _1zC1Ht6eJWJJyZdbOs3xEf 500ms infinite linear;\n  animation: _1zC1Ht6eJWJJyZdbOs3xEf 500ms infinite linear;\n}\n@keyframes _1zC1Ht6eJWJJyZdbOs3xEf {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "_2l3-VJzFkZItN8rkJbUSeG",
	"rotation": "_1zC1Ht6eJWJJyZdbOs3xEf"
};
module.exports = ___CSS_LOADER_EXPORT___;
