import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import uiMessageStore, {
  MESSAGE_TYPE_INFO,
  MESSAGE_TYPE_ERROR,
} from '../stores/uiMessageStore'
import './MessageBar.scss'

const MessageBar = () => {
  const [t] = useTranslation()
  const keys = Object.keys(uiMessageStore.messages)
  const empty = keys.length === 0
  const isInternetExplorer =
    (navigator.userAgent ?? '').match(/Trident/) != null

  return (
    <div
      className={classnames('common__message-bar', {
        'common__message-bar--visible': !empty,
      })}
    >
      {isInternetExplorer && (
        <div className="common__message-bar__message common__message-bar__message--browser-warning">
          {t('COMMON:INCOMPATIBLE_BROWSER_IE')}
        </div>
      )}
      {keys.map((key, index) => (
        <div
          key={index}
          onClick={() => uiMessageStore.clear(key)}
          className={classnames('common__message-bar__message', {
            'common__message-bar__message--info':
              uiMessageStore.messages[key] === MESSAGE_TYPE_INFO,
            'common__message-bar__message--error':
              uiMessageStore.messages[key] === MESSAGE_TYPE_ERROR,
          })}
        >
          {key}
        </div>
      ))}
    </div>
  )
}

export default observer(MessageBar)
