"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParkTypes = exports.ParkFacilityTypes = void 0;
var ParkFacilityTypes;
(function (ParkFacilityTypes) {
    ParkFacilityTypes["BICYCLE"] = "BICYCLE";
    ParkFacilityTypes["CAR"] = "CAR";
})(ParkFacilityTypes = exports.ParkFacilityTypes || (exports.ParkFacilityTypes = {}));
var ParkTypes;
(function (ParkTypes) {
    ParkTypes["CAR"] = "CAR";
    ParkTypes["ELECTRIC_CAR"] = "ELECTRIC_CAR";
    ParkTypes["BICYCLE"] = "BICYCLE";
    ParkTypes["BICYCLE_SECURE_SPACE"] = "BICYCLE_SECURE_SPACE";
    ParkTypes["DISABLED"] = "DISABLED";
    ParkTypes["MOTORCYCLE"] = "MOTORCYCLE";
})(ParkTypes = exports.ParkTypes || (exports.ParkTypes = {}));
