import React from 'react'
import PropTypes from 'prop-types'
import {
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  Navigate,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { multilingualRoutesToPath } from '../routes'

export const Link = ({ route, params = {}, query = {}, ...rest }) => {
  const [, i18n] = useTranslation()
  const to = multilingualRoutesToPath(route, params, query, i18n.language)

  return <ReactRouterLink to={to} {...rest} />
}

Link.propTypes = {
  route: PropTypes.object.isRequired,
  params: PropTypes.object,
  query: PropTypes.object,
}

export const NavLink = ({ route, params = {}, query = {}, ...rest }) => {
  const [, i18n] = useTranslation()
  const to = multilingualRoutesToPath(route, params, query, i18n.language)

  return <ReactRouterNavLink to={to} {...rest} />
}

NavLink.propTypes = {
  route: PropTypes.object.isRequired,
  params: PropTypes.object,
  query: PropTypes.object,
}

export const Redirect = ({ route, params = {}, query = {}, replace }) => {
  const [, i18n] = useTranslation()
  const to = multilingualRoutesToPath(route, params, query, i18n.language)

  return <Navigate to={to} replace={replace} />
}

Redirect.propTypes = {
  route: PropTypes.object.isRequired,
  params: PropTypes.object,
  query: PropTypes.object,
  replace: PropTypes.bool,
}
