import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import AdminPage from '../../../common/components/AdminPage'
import { H1, P, Ul } from '../../../common/components/Typography'
import Hero from '../../../common/components/Hero'
import WideContent from '../../../common/components/WideContent'
import { Link } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import PinInput from '../../../common/components/PinInput'

const TestWithParamScreen = () => {
  const params = useParams()
  const [pin, setPin] = useState('')

  return (
    <AdminPage>
      <Hero title="Test UI with Params" />
      <WideContent withVerticalPadding withTopMargin>
        <H1>{params.id}</H1>
        <P>This page is only used for testing development features.</P>
        <Ul
          items={[
            <Link key={1} route={ROUTES.ROOT} params={{ id: 12345 }}>
              Root
            </Link>,
            <Link
              key={2}
              route={ROUTES.COMPANY_ADMIN.DEV_TEST}
              params={{ id: 12345 }}
            >
              Dev test
            </Link>,
            <Link
              key={3}
              route={ROUTES.COMPANY_ADMIN.DEV_TEST_WITH_PARAM}
              params={{ id: 12345 }}
            >
              Dev test with params
            </Link>,
            <Link
              key={4}
              route={ROUTES.COMPANY_ADMIN.DEV_TEST_WITH_PARAM}
              params={{ id: 12345 }}
              query={{ foo: 'bar', baz: 'qux' }}
            >
              Dev test with params and query string
            </Link>,
          ]}
        />
        <PinInput
          label="PIN"
          value={pin}
          digits={4}
          onChange={(value) => setPin(value)}
        />
      </WideContent>
    </AdminPage>
  )
}

export default observer(TestWithParamScreen)
