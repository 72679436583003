import SiteFooter from '@hsl-fi/site-footer'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg'
import config from '../config'

const Footer = () => {
  const [t, i18n] = useTranslation()

  return (
    <>
      <SiteFooter
        hslFiUrl={config.HSL_HOME_URL}
        assetsUrl={config.HSL_CONTENT_URL}
        lang={i18n.language}
        onCookieButtonClick={() => {
          if (window.CookieConsent) {
            window.CookieConsent.renew()
          }
        }}
        firstColumn={[
          {
            name: (
              <FooterLinkName>
                {t('COMMON:HSL_SITE_FOOTER.CUSTOMER_SUPPORT_LABEL')}
              </FooterLinkName>
            ),
            url: t('COMMON:HSL_SITE_FOOTER.CUSTOMER_SUPPORT_URL'),
            selected: false,
            target: '_blank',
          },
          {
            name: (
              <FooterLinkName>{t('COMMON:INSTRUCTIONS_LABEL')}</FooterLinkName>
            ),
            url: t('COMMON:HSL_SITE_FOOTER.INSTRUCTIONS_URL'),
            selected: false,
            target: '_blank',
          },
        ]}
      />
    </>
  )
}

const FooterLinkName = ({ children }) => (
  <span className="inline-flex items-center w-full">
    {children} <LinkIcon className="ml-auto size-md " />
  </span>
)

FooterLinkName.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
