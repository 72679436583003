"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointOfSaleServiceType = exports.PointOfSaleTypes = void 0;
var PointOfSaleTypes;
(function (PointOfSaleTypes) {
    PointOfSaleTypes[PointOfSaleTypes["UNKNOWN"] = 0] = "UNKNOWN";
    PointOfSaleTypes[PointOfSaleTypes["POINT_OF_SERVICE"] = 1] = "POINT_OF_SERVICE";
    PointOfSaleTypes[PointOfSaleTypes["POINT_OF_SALE"] = 2] = "POINT_OF_SALE";
    PointOfSaleTypes[PointOfSaleTypes["MACHINE_SINGLE_TICKET"] = 3] = "MACHINE_SINGLE_TICKET";
    PointOfSaleTypes[PointOfSaleTypes["MACHINE_MULTI_TICKET"] = 4] = "MACHINE_MULTI_TICKET";
    PointOfSaleTypes[PointOfSaleTypes["MACHINE_PARKING_TICKET"] = 5] = "MACHINE_PARKING_TICKET";
})(PointOfSaleTypes = exports.PointOfSaleTypes || (exports.PointOfSaleTypes = {}));
var PointOfSaleServiceType;
(function (PointOfSaleServiceType) {
    PointOfSaleServiceType[PointOfSaleServiceType["CARD_TOP_UP"] = 1] = "CARD_TOP_UP";
    PointOfSaleServiceType[PointOfSaleServiceType["MANAGE_PERSONAL_CARD"] = 2] = "MANAGE_PERSONAL_CARD";
    PointOfSaleServiceType[PointOfSaleServiceType["MANAGE_MULTI_USER_CARD"] = 11] = "MANAGE_MULTI_USER_CARD";
    PointOfSaleServiceType[PointOfSaleServiceType["DAY_TICKETS_1_TO_7"] = 3] = "DAY_TICKETS_1_TO_7";
    PointOfSaleServiceType[PointOfSaleServiceType["ADVANCE_PURCHASE_SINGLE_TICKETS"] = 4] = "ADVANCE_PURCHASE_SINGLE_TICKETS";
    PointOfSaleServiceType[PointOfSaleServiceType["PAPER_SINGLE_TICKET"] = 5] = "PAPER_SINGLE_TICKET";
    PointOfSaleServiceType[PointOfSaleServiceType["DAY_TICKET_SINGLE_ABC"] = 6] = "DAY_TICKET_SINGLE_ABC";
    PointOfSaleServiceType[PointOfSaleServiceType["COMMUTER_TICKET_AND_GIFT_CARD_SUPPORT"] = 7] = "COMMUTER_TICKET_AND_GIFT_CARD_SUPPORT";
    PointOfSaleServiceType[PointOfSaleServiceType["PAYMENT_BANKNOTES"] = 8] = "PAYMENT_BANKNOTES";
    PointOfSaleServiceType[PointOfSaleServiceType["PAYMENT_COINS"] = 9] = "PAYMENT_COINS";
    PointOfSaleServiceType[PointOfSaleServiceType["PAYMENT_CREDIT_CARDS"] = 10] = "PAYMENT_CREDIT_CARDS";
})(PointOfSaleServiceType = exports.PointOfSaleServiceType || (exports.PointOfSaleServiceType = {}));
