import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '../Button'

const PaginationButton = ({ className, children, ...props }) => (
  <Button
    small
    transparent
    className={classNames(
      'p-0 size-[44px] text-lg text-blue font-medium',
      className
    )}
    {...props}
  >
    <div className="flex">{children}</div>
  </Button>
)

PaginationButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default PaginationButton
