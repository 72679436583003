import PropTypes from 'prop-types'

const Main = ({ children, style }) => (
  <main className="min-h-screen" style={style}>
    {children}
  </main>
)

Main.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Main
