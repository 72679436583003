import { useQuery } from '@tanstack/react-query'
import apiStore from '../../../common/stores/apiStore'
import companiesQueryKeys from '../companiesQueryKeys'

const useCompanyByIdQuery = (companyId) => {
  return useQuery({
    queryKey: companiesQueryKeys.single(companyId),
    enabled: !!companyId,
    queryFn: async () => {
      return await apiStore.endpoints.company.getCompanyById(companyId)
    },
  })
}

export default useCompanyByIdQuery
