import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AnimatedSpinner from '@hsl-fi/animated-spinner'
import './Spinner.scss'

const Spinner = ({ size = 40, centered = false, style, className }) => (
  <div
    className={classnames('common__spinner', className, {
      'common__spinner--centered': centered,
    })}
    style={style}
  >
    <AnimatedSpinner size={size} />
  </div>
)

Spinner.propTypes = {
  size: PropTypes.number,
  centered: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default Spinner
