"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodes = void 0;
// Error codes that API calls may return in the response Body (they are originally from HSLID):
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["EMAIL_EXISTS"] = "email-exists";
    ErrorCodes["EMAIL_INVALID"] = "email-invalid";
    ErrorCodes["MOBILE_EXISTS"] = "mobile-exists";
    ErrorCodes["MOBILE_INVALID"] = "mobile-invalid";
    ErrorCodes["SCREEN_NAME_IN_USE"] = "screenname-in-use";
    ErrorCodes["SCREEN_NAME_REQUIREMENTS_NOT_MET"] = "screenname-requirements-not-met";
})(ErrorCodes = exports.ErrorCodes || (exports.ErrorCodes = {}));
