import companiesQueryKeys from '../../../../company/companiesQueryKeys'

const adminsQueryKeys = {
  root: (companyId) => [...companiesQueryKeys.single(companyId), 'admins'],
  adminList: (companyId, options) => [
    ...adminsQueryKeys.root(companyId),
    'adminsList',
    options,
  ],
  adminInvites: (companyId, options) => [
    ...adminsQueryKeys.root(companyId),
    'adminInvites',
    options,
  ],
}

export default adminsQueryKeys
