import classnames from 'classnames'
import PropTypes from 'prop-types'
import './AdminPage.scss'
import Footer from './Footer'
import Header from './Header'
import Main from './Main'
import MessageBar from './MessageBar'

const AdminPage = ({ children, backgroundColor, className, ...props }) => (
  <>
    <Header />
    <Main style={{ backgroundColor: backgroundColor ?? 'transparent' }}>
      <div className={classnames('common__admin-page', className)} {...props}>
        {children}
      </div>
    </Main>
    <Footer />
    <MessageBar />
  </>
)

AdminPage.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
}

export default AdminPage
