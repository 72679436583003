import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Page from '../../../common/components/Page'
import { observer } from 'mobx-react'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, P } from '../../../common/components/Typography'
import { ExternalLinkButton } from '../../../common/components/Button'

const StrongAuthenticationScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')

  return (
    <Page>
      <WhiteBox>
        <H1>{t('SELF_SERVICE:STRONG_AUTHENTICATION_SCREEN.HEADING')}</H1>
        <P>{t('SELF_SERVICE:STRONG_AUTHENTICATION_SCREEN.PARAGRAPH_1')}</P>
        <P>{t('SELF_SERVICE:STRONG_AUTHENTICATION_SCREEN.PARAGRAPH_2')}</P>
        <ExternalLinkButton
          href={`/api/self-service/strong-authentication?inviteId=${inviteId}`}
        >
          {t(
            'SELF_SERVICE:STRONG_AUTHENTICATION_SCREEN.CONTINUE_STRONG_AUTHENTICATION'
          )}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default observer(StrongAuthenticationScreen)
