"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUrl = void 0;
var DEFAULT_PATHS = {
    search: {
        fi: '/haku',
        sv: '/sv/sok',
        en: '/en/search'
    },
    userSelections: {
        fi: '/omat-tiedot/omat-valinnat',
        sv: '/sv/mina-uppgifter/mina-val',
        en: '/en/my-information/my-options'
    }
};
var getUrl = function (_a) {
    var page = _a.page, lang = _a.lang, hslFiUrl = _a.hslFiUrl, urlOverride = _a.urlOverride;
    if (urlOverride)
        return urlOverride;
    var hslFiUrlStripped = hslFiUrl.endsWith('/')
        ? hslFiUrl.slice(0, -1)
        : hslFiUrl;
    var path = DEFAULT_PATHS[page][lang] || DEFAULT_PATHS[page]['fi'];
    return "" + hslFiUrlStripped + path;
};
exports.getUrl = getUrl;
exports.default = exports.getUrl;
