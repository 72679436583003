import {
  ELECTRONIC_INVOICE,
  PAPER_INVOICE,
} from '../../../common/components/BillingMethodInput'
import {
  validatePhoneNumber,
  validateEmail,
  validateBusinessId,
  validatePostalCode,
} from '../../../common/validate'

const validateSelfServiceForm = (form, t) => {
  const errors = {}

  if (!form.companyName) {
    errors.companyName = t('COMMON:REQUIRED_FIELD_X', {
      field: t('COMMON:COMPANY_NAME'),
    })
  }

  if (!validateBusinessId(form.businessId)) {
    errors.businessId = t('COMMON:INVALID_BUSINESS_ID', {
      field: t('COMMON:BUSINESS_ID'),
    })
  }

  if (!form.numberOfEmployees) {
    errors.numberOfEmployees = t('COMMON:REQUIRED_FIELD_X', {
      field: t('SELF_SERVICE:SELF_SERVICE_SCREEN.NUMBER_OF_EMPLOYEES'),
    })
  }

  if (!form.officeStreetAddress) {
    errors.officeStreetAddress = `${t('COMMON:OFFICE_ADDRESS')}: ${t(
      'COMMON:REQUIRED_FIELD_X',
      { field: t('COMMON:STREET_ADDRESS') }
    )}`
  }

  if (!validatePostalCode(form.officePostalCode)) {
    errors.officePostalCode = `${t('COMMON:OFFICE_ADDRESS')}: ${t(
      'COMMON:INVALID_POSTAL_CODE',
      { field: t('COMMON:POSTAL_CODE') }
    )}`
  }

  if (!form.officeCity) {
    errors.officeCity = `${t('COMMON:OFFICE_ADDRESS')}: ${t(
      'COMMON:REQUIRED_FIELD_X',
      { field: t('COMMON:CITY') }
    )}`
  }

  if (!form.useOfficeAsMailingAddress) {
    if (!form.mailingStreetAddress) {
      errors.mailingStreetAddress = `${t(
        'SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS'
      )}: ${t('COMMON:REQUIRED_FIELD_X', {
        field: t('COMMON:STREET_ADDRESS'),
      })}`
    }

    if (!form.mailingPostalCode) {
      errors.mailingPostalCode = `${t(
        'SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS'
      )}: ${t('COMMON:REQUIRED_FIELD_X', { field: t('COMMON:POSTAL_CODE') })}`
    } else if (!validatePostalCode(form.mailingPostalCode)) {
      errors.mailingPostalCode = `${t(
        'SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS'
      )}: ${t('COMMON:INVALID_POSTAL_CODE', {
        field: t('COMMON:POSTAL_CODE'),
      })}`
    }

    if (!form.mailingCity) {
      errors.mailingCity = `${t(
        'SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS'
      )}: ${t('COMMON:REQUIRED_FIELD_X', { field: t('COMMON:CITY') })}`
    }
  }

  if (![ELECTRONIC_INVOICE, PAPER_INVOICE].includes(form.billingMethod)) {
    errors.billingMethod = t('COMMON:REQUIRED_FIELD_X', {
      field: t('COMMON:BILLING_METHODS.BILLING_METHOD'),
    })
  }

  if (form.billingMethod === ELECTRONIC_INVOICE) {
    if (!form.billingIntermediary) {
      errors.billingIntermediary = t('COMMON:REQUIRED_FIELD_X', {
        field: t('COMMON:BILLING_METHODS.BILLING_INTERMEDIARY'),
      })
    }

    if (!form.electronicInvoiceAddress) {
      errors.electronicInvoiceAddress = t('COMMON:REQUIRED_FIELD_X', {
        field: t('COMMON:BILLING_METHODS.ELECTRONIC_INVOICE_ADDRESS'),
      })
    }
  }

  if (!form.contactName) {
    errors.contactName = t('COMMON:REQUIRED_FIELD_X', {
      field: t('SELF_SERVICE:SELF_SERVICE_SCREEN.CONTACT_NAME'),
    })
  }

  if (!validatePhoneNumber(form.contactPhoneNumber)) {
    errors.contactPhoneNumber = t('COMMON:INVALID_PHONE_NUMBER', {
      field: t('COMMON:PHONE'),
    })
  }

  if (!validateEmail(form.contactWorkEmail)) {
    errors.contactWorkEmail = t('COMMON:INVALID_EMAIL', {
      field: t('COMMON:WORK_EMAIL'),
    })
  }

  return errors
}

export const validateSelfServiceAgreement = (form, t) => {
  const errors = {}

  if (!form.companyPermission) {
    errors.companyPermission = t('COMMON:REQUIRED_FIELD_X', {
      field: t(
        'SELF_SERVICE:APPLICATION_CONFIRMATION_SCREEN.COMPANY_PERMISSION_CHECKBOX_LABEL'
      ),
    })
  }

  return errors
}

export default validateSelfServiceForm
