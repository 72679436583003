import { useMutation, useQueryClient } from '@tanstack/react-query'
import apiStore from '../../../../common/stores/apiStore'
import companiesQueryKeys from '../../../company/companiesQueryKeys'

const useAcceptTOSMutation = ({ companyId }) => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      return await apiStore.endpoints.tos.acceptTermsOfService(companyId)
    },
    onSuccess: () =>
      client.invalidateQueries(companiesQueryKeys.single(companyId)),
  })
}

export default useAcceptTOSMutation
