import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import Page from '../../../common/components/Page'
import WideContent from '../../../common/components/WideContent'
import { H1, P } from '../../../common/components/Typography'
import { Button, LinkButton } from '../../../common/components/Button'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import apiStore from '../../../common/stores/apiStore'
import MessageBar from '../../../common/components/MessageBar'
import ROUTES from '../../routes/routes'
import TextInput from '../../../common/components/TextInput'
import DecimalInput from '../../../common/components/DecimalInput'
import PinInput from '../../../common/components/PinInput'
import Map, { flipCoordinates } from '../../../common/components/Map'
import useQueryParams from '../../../common/hooks/useQueryParams'
import AutocompleteAddress from '../../../common/components/AutocompleteAddress'
import FiftyFifty from '../../../common/components/FiftyFifty'
import Hr from '../../../common/components/Hr'
import { captureException } from '../../../common/errorTracking'

const TestScreen = () => {
  const [money, setMoney] = useState()
  const [queryParamState, setQueryParamState] = useQueryParams({
    foo: 'bar',
  })
  const [addressQuery, setAddressQuery] = useState('')
  const [addressCoordinates, setAddressCoordinates] = useState()
  const [pin, setPin] = useState('')

  const flippedAddressCoordinates = useMemo(
    () =>
      addressCoordinates ? flipCoordinates(addressCoordinates) : undefined,
    [addressCoordinates]
  )

  const markers = useMemo(
    () =>
      [
        {
          position: flippedAddressCoordinates,
          label: 'Address',
        },
      ].filter((marker) => Boolean(marker.position)),
    [flippedAddressCoordinates]
  )

  return (
    <>
      <Page>
        <WideContent withVerticalPadding withTopMargin withBottomMargin>
          <H1>Test Screen</H1>
          <P>This page is only used for testing development features.</P>
          <FiftyFifty
            left={
              <>
                <TextInput
                  label="Query param 'foo'"
                  value={queryParamState.foo}
                  onChange={({ target }) =>
                    setQueryParamState({ foo: target.value })
                  }
                />
                <TextInput
                  label="Query param 'bar'"
                  value={queryParamState.bar}
                  onChange={({ target }) =>
                    setQueryParamState({ bar: target.value })
                  }
                />
              </>
            }
            hideSeparator
          />
          <div style={sectionStyle}>
            <Button
              onClick={() => {
                uiMessageStore.addMessage('foo')
              }}
              style={{ marginRight: '20px' }}
            >
              UI message alert
            </Button>
            <Button
              onClick={() => {
                uiMessageStore.addError('bar')
              }}
            >
              UI error alert
            </Button>
          </div>
          <div style={sectionStyle}>
            <Button
              onClick={() => {
                throw new Error('foo')
              }}
            >
              Throw a client error
            </Button>
          </div>
          <div style={sectionStyle}>
            <Button
              onClick={() =>
                captureException({
                  message: 'Test event',
                  extra: { foo: 'bar', timestamp: Number(new Date()) },
                })
              }
            >
              Capture a client event
            </Button>
          </div>
          <div style={sectionStyle}>
            <Button
              onClick={async () => {
                try {
                  await apiStore.endpoints.test.throwError()
                } catch (e) {
                  window.alert('Server error!')
                }
              }}
            >
              Call a Node endpoint that throws
            </Button>
          </div>
          <div style={sectionStyle}>
            <Button
              onClick={async () => {
                try {
                  const response = await apiStore.endpoints.test.captureEvent()
                  window.alert(JSON.stringify(response))
                } catch (e) {
                  window.alert('Server error!')
                }
              }}
            >
              Call a Node endpoint that captures an event
            </Button>
          </div>
          <div style={sectionStyle}>
            <Button
              onClick={async () => await apiStore.endpoints.test.slowRequest()}
              style={{ marginRight: '20px' }}
            >
              Slow request
            </Button>
            <Button
              onClick={async () =>
                await apiStore.endpoints.test.slowerRequest()
              }
              style={{ marginRight: '20px' }}
            >
              Slower request
            </Button>
            <LinkButton
              secondary
              route={ROUTES.COMPANY_ADMIN.DEV_TEST_WITH_PARAM}
              params={{ id: 'foobar' }}
            >
              Link to other test screen with params
            </LinkButton>
          </div>
          <Hr style={{ marginTop: '30px', marginBottom: '25px' }} />
          <div style={sectionStyle}>
            <FiftyFifty
              left={
                <DecimalInput
                  label={`Money (type of value: ${typeof money})`}
                  defaultValue={money}
                  onChange={(value) => setMoney(value)}
                />
              }
              hideSeparator
            />
          </div>
          <Hr style={{ marginBottom: '25px' }} />
          <div style={sectionStyle}>
            <FiftyFifty
              left={
                <AutocompleteAddress
                  value={addressQuery}
                  label="Autocomplete address and map"
                  onType={(value) => {
                    setAddressQuery(value)
                  }}
                  onSelect={(option) => {
                    setAddressCoordinates(option.coordinates)
                  }}
                  onDismiss={() => {
                    setAddressQuery('')
                  }}
                />
              }
              right={
                <>
                  {addressCoordinates && (
                    <Map
                      position={flippedAddressCoordinates}
                      markers={markers}
                      style={{
                        height: '400px',
                        width: '400px',
                      }}
                    />
                  )}
                </>
              }
              hideSeparator
            />
          </div>
          <Hr style={{ marginBottom: '25px' }} />
          <div style={sectionStyle}>
            <PinInput
              label="PIN number"
              value={pin}
              digits={4}
              hasError={false}
              onChange={(target) => setPin(target)}
            />
          </div>
        </WideContent>
      </Page>
      <MessageBar />
    </>
  )
}

const sectionStyle = {
  marginBottom: '20px',
}

export default observer(TestScreen)
