"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CitybikeMunicipalities = exports.TransportationServiceFields = exports.IdentifierTypes = exports.LocationTypes = exports.JourneyInformationFields = void 0;
var JourneyInformationFields;
(function (JourneyInformationFields) {
    JourneyInformationFields["ASCENT_STOP"] = "ascentStop";
    JourneyInformationFields["CARD_NUMBER"] = "cardNumber";
    JourneyInformationFields["DATE"] = "date";
    JourneyInformationFields["EXIT_STOP"] = "exitStop";
    JourneyInformationFields["IDENTIFIER"] = "identifier";
    JourneyInformationFields["LINE"] = "line";
    JourneyInformationFields["LOCATION"] = "location";
    JourneyInformationFields["SERVICE"] = "service";
    JourneyInformationFields["STOPNUMBER"] = "stopnumber";
    JourneyInformationFields["TIME"] = "time";
})(JourneyInformationFields = exports.JourneyInformationFields || (exports.JourneyInformationFields = {}));
var LocationTypes;
(function (LocationTypes) {
    LocationTypes["PARKING"] = "PARKING";
    LocationTypes["MUNICIPALITY"] = "MUNICIPALITY";
    LocationTypes["SALES_LOCATION"] = "SALES_LOCATION";
    LocationTypes["POINT_OF_SALES"] = "POINT_OF_SALES";
    LocationTypes["FREE_TEXT"] = "FREE_TEXT";
    LocationTypes["CITYBIKE_AREA"] = "CITYBIKE_AREA";
})(LocationTypes = exports.LocationTypes || (exports.LocationTypes = {}));
var IdentifierTypes;
(function (IdentifierTypes) {
    IdentifierTypes["CAR_OR_CARRIAGE_NUMBER"] = "CAR_OR_CARRIAGE_NUMBER";
    IdentifierTypes["TICKET_MACHINE_NUMBER"] = "TICKET_MACHINE_NUMBER";
    IdentifierTypes["APPLICATION_INFORMATION"] = "APPLICATION_INFORMATION";
    IdentifierTypes["BICYCLE_NUMBER"] = "BICYCLE_NUMBER";
    IdentifierTypes["CITYBIKE_STATION_NAME"] = "CITYBIKE_STATION_NAME";
})(IdentifierTypes = exports.IdentifierTypes || (exports.IdentifierTypes = {}));
var TransportationServiceFields;
(function (TransportationServiceFields) {
    TransportationServiceFields["ASCENT_STOP"] = "ascentStop";
    TransportationServiceFields["EXIT_STOP"] = "exitStop";
    TransportationServiceFields["IDENTIFIER"] = "identifier";
    TransportationServiceFields["STOPNUMBER"] = "stopnumber";
})(TransportationServiceFields = exports.TransportationServiceFields || (exports.TransportationServiceFields = {}));
var CitybikeMunicipalities;
(function (CitybikeMunicipalities) {
    CitybikeMunicipalities["HELSINKI_ESPOO"] = "Helsinki ja Espoo";
    CitybikeMunicipalities["VANTAA"] = "Vantaa";
})(CitybikeMunicipalities = exports.CitybikeMunicipalities || (exports.CitybikeMunicipalities = {}));
