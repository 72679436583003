import { useTranslation } from 'react-i18next'
import { ExternalLinkButton } from '../../../common/components/Button'
import Page from '../../../common/components/Page'
import { H1 } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'

const NotFoundScreen = () => {
  const [t] = useTranslation()

  return (
    <Page>
      <WhiteBox>
        <H1>{t('ANONYMOUS:NOT_FOUND_SCREEN.TITLE')}</H1>
        <ExternalLinkButton secondary href={t('COMMON:HSL_HOME_URL')}>
          {t('COMMON:GO_TO_HSL_FI')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default NotFoundScreen
