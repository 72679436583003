import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { MapContainer, TileLayer, Marker, FeatureGroup } from 'react-leaflet'
import MARKER_ICON from '../../assets/icons/map-marker.svg'
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'

const Map = ({ position, markers, ...rest }) => {
  const [map, setMap] = useState()
  const featureGroupRef = useRef(null)

  useEffect(() => {
    if (map) {
      map.setView(position)

      if (featureGroupRef) {
        map.fitBounds(featureGroupRef.current.getBounds(), {
          padding: [40, 40],
        }) // fit all markers on the map
      }
    }
  }, [map, position, markers])

  return (
    <MapContainer
      center={position}
      zoom={16}
      maxZoom={16}
      zoomSnap={0.1}
      scrollWheelZoom={false}
      attributionControl={false}
      ref={(el) => setMap(el)}
      {...rest}
    >
      <TileLayer url="/api/rest/digitransit/map/v2/hsl-map/{z}/{x}/{y}.png" />
      <FeatureGroup ref={featureGroupRef}>
        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position} icon={icon} />
        ))}
      </FeatureGroup>
    </MapContainer>
  )
}

Map.propTypes = {
  position: PropTypes.array,
  markers: PropTypes.array,
}

const MARKER_WIDTH = 20
const MARKER_HEIGHT = 30

const icon = Leaflet.icon({
  iconUrl: MARKER_ICON,
  iconSize: new Leaflet.Point(MARKER_WIDTH, MARKER_HEIGHT),
  iconAnchor: new Leaflet.Point(MARKER_WIDTH / 2, MARKER_HEIGHT),
})

// Leaflet expects a latitude value to come before longitude. Digitransit's geocoding API
// provides GeoJSON objects where the longitude comes first which is why we need to reverse
// the order.
export const flipCoordinates = (coordinates) => {
  const [longitude, latitude] = coordinates

  return [latitude, longitude]
}

export default Map
