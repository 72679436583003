import React from 'react'
import Authenticated from '../components/Authenticated'

const withAuthentication = (WrappedComponent) => {
  const ComponentWithAuthentication = (props) => (
    <Authenticated>
      <WrappedComponent {...props} />
    </Authenticated>
  )

  return ComponentWithAuthentication
}

export default withAuthentication
