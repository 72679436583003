import { parseISO, startOfDay, isSameDay, isAfter } from 'date-fns'
import i18n from './i18n'

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const MONEY_REGEX = /^(\d+(?:[.,]\d{1,2})?)$/

export const validateEmail = (value) => EMAIL_REGEX.test(value)

// Extracts valid emails from a single string.
// Emails can be separated by a space, comma, semicolon, or new line.
// All emails must be valid or otherwise the function will return undefined.
export const extractEmailsFromString = (value) => {
  if (typeof value !== 'string') {
    return
  }

  const emails = value
    .replace(/\r\n|\s|\n|;/g, ',')
    .split(',')
    .filter((row) => row.length > 0)

  if (!emails.length) {
    return
  }

  for (const email of emails) {
    if (!validateEmail(email)) {
      return
    }
  }

  return emails
}

export const validatePhoneNumber = (phoneNumber) =>
  /^\+[0-9]{7,20}$/.test(phoneNumber)

export const validateDiscountDateRange = (
  from,
  to,
  ignoreTo,
  previouslySavedCompanyForm
) => {
  const now = new Date()

  if (previouslySavedCompanyForm) {
    if (
      !previouslySavedCompanyForm.discountPercentage &&
      startOfDay(from) < startOfDay(now)
    ) {
      // When adding a new discount (not modifying an existing one, 'from' must NOT be in the past)
      return false
    }

    // If the previous discount has expired let the admin create a new one
    if (isAfter(now, parseISO(previouslySavedCompanyForm.discountEndDate)))
      return true

    if (
      previouslySavedCompanyForm.discountPercentage &&
      !isSameDay(from, parseISO(previouslySavedCompanyForm.discountStartDate))
    ) {
      // Should not change the start date of an already active discount
      return false
    }
  }

  if (ignoreTo) {
    return true
  }

  return from < to && startOfDay(now) <= startOfDay(to)
}

export const validateBeneficiaryDateRange = (from, to, ignoreTo) => {
  if (ignoreTo) {
    return true
  }

  const now = new Date()

  return from < to && startOfDay(now) <= startOfDay(to)
}

export const isEndDateValid = (startDate, endDate) =>
  startOfDay(startDate) < startOfDay(endDate)

export const validateCompanyName = (name) => /[a-zåäö]/i.test(name)

export const validateCompanyD365FOId = (value) => {
  const valueAsString = String(value)
  const [firstCharacter] = valueAsString

  if (valueAsString.length > 20) {
    return false
  }

  // Old D365FO IDs that start with a 6 are 7 digits long
  // New D365FO IDs that start with a 1 are 7 digits long
  if (firstCharacter === '6' || firstCharacter === '1') {
    return /^\d{7,}$/.test(value)
  }

  return /^\d{8,}$/.test(value)
}

// Y-tunnus
export const validateBusinessId = (value) => /^[\d]{7}-[\d]$/.test(value)

export const validatePostalCode = (value) => /^\d{5}$/.test(value)

export const validateMoney = (value) => MONEY_REGEX.test(String(value))

export const validateTextInput = (string, setError) => {
  const trimmedString = string.trim()

  if (trimmedString.length === 0)
    setError(() => i18n.t('COMMON:REQUIRED_FIELD'))
  else setError(null)

  return trimmedString.length === 0
}

export const checkForCommonElements = (arr1, arr2, setError, errorMessage) => {
  if (!arr1 || !arr2) return

  const set1 = new Set(arr1)
  const commonItems = arr2.filter((item) => set1.has(item))

  if (commonItems.length > 0) setError(() => errorMessage)
  else setError(() => null)

  return commonItems.length > 0
}
