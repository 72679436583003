"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToDigitransitStop = exports.convertToEpiserverFavouritePlace = exports.convertToDigitransitFavouritePlace = exports.generateDirectionsUrl = void 0;
var generateDirectionsUrl = function (item, journeyPlannerUrl, origin, lang) {
    var title = item.title, city = item.city, latitude = item.latitude, longitude = item.longitude;
    var parameter = "" + title + (city && ", " + city) + "::" + latitude + "," + longitude;
    return journeyPlannerUrl + "/" + lang + "/reitti/" + origin + "/" + encodeURIComponent(parameter);
};
exports.generateDirectionsUrl = generateDirectionsUrl;
var convertToDigitransitFavouritePlace = function (favouritePlace) {
    return {
        favouriteId: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.id,
        gid: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.gid,
        name: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.name,
        address: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.address,
        lat: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.latitude,
        lon: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.longitude,
        selectedIconId: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.icon,
        type: undefined,
        lastUpdate: undefined
    };
};
exports.convertToDigitransitFavouritePlace = convertToDigitransitFavouritePlace;
var convertToEpiserverFavouritePlace = function (favouritePlace) {
    return {
        id: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.favouriteId,
        name: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.name,
        icon: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.selectedIconId,
        gid: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.gid,
        address: favouritePlace === null || favouritePlace === void 0 ? void 0 : favouritePlace.address,
        postalcode: undefined,
        municipality: undefined,
        latitude: favouritePlace.lat,
        longitude: favouritePlace.lon,
        sortOrder: undefined
    };
};
exports.convertToEpiserverFavouritePlace = convertToEpiserverFavouritePlace;
var convertToDigitransitStop = function (favouriteStop) {
    return {
        favouriteId: undefined,
        type: 'stop',
        gtfsId: favouriteStop === null || favouriteStop === void 0 ? void 0 : favouriteStop.gtfsId,
        address: undefined,
        code: favouriteStop === null || favouriteStop === void 0 ? void 0 : favouriteStop.code,
        lat: undefined,
        lon: undefined,
        lastUpdated: 1
    };
};
exports.convertToDigitransitStop = convertToDigitransitStop;
