import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import HSL_BG_LOGO from '../../assets/hsl-bg-logo.svg'
import LanguagePicker from './LanguagePicker'
import MessageBar from './MessageBar'
import { Link } from '../../modules/routes/components/Link'
import ROUTES from '../../modules/routes/routes'
import './Page.scss'

const Page = ({ children, className, ...props }) => {
  const [t] = useTranslation()

  return (
    <>
      <div
        className={classnames('common__page__background', className)}
        {...props}
      >
        <div className="common__page__header">
          <Link
            route={ROUTES.ROOT}
            aria-label={t('COMMON:HSL_SITE_HEADER.TO_FRONT_PAGE')}
          >
            <img
              className="common__page__hsl-bg-logo"
              src={HSL_BG_LOGO}
              alt={t('COMMON:HSL')}
            />
          </Link>
          <LanguagePicker />
        </div>
        <div className="common__page__body">{children}</div>
      </div>
      <MessageBar />
    </>
  )
}

Page.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default Page
