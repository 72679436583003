import { useQuery } from '@tanstack/react-query'
import apiStore from '../../../../../common/stores/apiStore'
import adminsQueryKeys from './adminsQueryKeys'

export const PENDING_INVITES_PAGE_SIZE = 10

export const adminInviteeType = 'ADMIN'

const useAdminInvitesQuery = ({ companyId, options }) => {
  return useQuery({
    queryKey: adminsQueryKeys.adminInvites(companyId, options),
    queryFn: async () => {
      return await apiStore.endpoints.invite.getBeneficiaryInvites(
        companyId,
        { inviteesType: adminInviteeType },
        options
      )
    },
  })
}

export default useAdminInvitesQuery
