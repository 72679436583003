"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagePreset = void 0;
var ImagePreset;
(function (ImagePreset) {
    ImagePreset["DEFAULT"] = "default";
    ImagePreset["960w"] = "960w";
    ImagePreset["1280w"] = "1280w";
    ImagePreset["1920w"] = "1920w";
    ImagePreset["2560w"] = "2560w";
    ImagePreset["BANNER"] = "1920w";
    ImagePreset["NEWS_FEED"] = "800w";
    ImagePreset["PREVIEW_LINK_ITEM"] = "800w";
    ImagePreset["IMAGE_TEASER"] = "1440w";
    ImagePreset["CONTENT_IMAGE"] = "1340w";
})(ImagePreset = exports.ImagePreset || (exports.ImagePreset = {}));
