"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavouritePlaceIcon = void 0;
var FavouritePlaceIcon;
(function (FavouritePlaceIcon) {
    FavouritePlaceIcon["MARKER"] = "icon-icon_place";
    FavouritePlaceIcon["HOME"] = "icon-icon_home";
    FavouritePlaceIcon["WORK"] = "icon-icon_work";
    FavouritePlaceIcon["SPORT"] = "icon-icon_sport";
    FavouritePlaceIcon["SCHOOL"] = "icon-icon_school";
    FavouritePlaceIcon["SHOPPING"] = "icon-icon_shopping";
})(FavouritePlaceIcon = exports.FavouritePlaceIcon || (exports.FavouritePlaceIcon = {}));
