import React from 'react'
import PropTypes from 'prop-types'
import APP_STORE_ICON from '../../assets/app-store.svg'
import GOOGLE_PLAY_ICON from '../../assets/google-play.svg'
import './AppStoreLink.scss'

export const AppStoreLink = ({ style, ...props }) => (
  <a href={HSL_APP_LINK_IOS} {...props}>
    <img
      src={APP_STORE_ICON}
      alt="Apple App Store"
      className="common__app-store-link__app-store"
      style={style}
    />
  </a>
)

AppStoreLink.propTypes = {
  style: PropTypes.object,
}

export const GooglePlayLink = ({ style, ...props }) => (
  <a href={HSL_APP_LINK_ANDROID} {...props}>
    <img
      src={GOOGLE_PLAY_ICON}
      alt="Google Play Store"
      className="common__app-store-link__google-play"
      style={style}
    />
  </a>
)

GooglePlayLink.propTypes = {
  style: PropTypes.object,
}

const HSL_APP_LINK_ANDROID =
  'https://play.google.com/store/apps/details?id=fi.hsl.app&referrer=utm_source%3Dsovellus%26utm_medium%3Dreferral%26utm_campaign%3Dhsl.fi%26anid%3Dadmob'
const HSL_APP_LINK_IOS =
  'https://itunes.apple.com/fi/app/apple-store/id1340229182?pt=118087532&ct=hsl.fi-sovellus&mt=8&l=fi'
